import React from 'react';
import range from 'lodash/range';
import GuestsInput from 'components/HeroSearchForm/GuestsInput';
import StayDatesRangeInput from 'components/HeroSearchForm/StayDatesRangeInput';
import { DateRange, Guests } from 'hooks/filter/types';
import useWindowSize from 'hooks/useWindowResize';
import moment from 'moment';
import momentLocale from 'helpers/momentLocale';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';
import ModalPhotos from './ModalPhotos';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import { useRooms } from 'hooks/rooms';
import { useCalendar } from 'hooks/calendar';
import { useReservation } from 'hooks/reservation';
import { Amenity } from 'hooks/rooms/types';
import { formatMoney } from 'helpers/formatMoney';
import SectionGridFeaturePlaces from 'containers/PageHome/SectionGridFeaturePlaces';
import { useLocation } from 'react-router-dom';
import { enumerateDaysBetweenDates } from 'utils/daysBetween';
import { useFilter } from 'hooks/filter';

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

interface AmenitiesData {
  data: {
    attributes: Amenity;
    id: number;
  }[];
}

const defaultGuestValue: Guests = {
  adultMembersGuests: 0,
  adultNonMembersGuests: 0,
  kidMembersGuests: 0,
  kidNonMembersGuests: 0,
};

const defaultSelectedDates: DateRange = {
  startDate: null,
  endDate: null,
};

const ListingStayDetailPage: React.FC<ListingStayDetailPageProps> = ({
  className = '',
  isPreviewMode,
}) => {
  const { filter } = useFilter();
  const { pathname } = useLocation();
  const { seasonalDates, blockDates } = useCalendar();
  const { reservation, setReservation } = useReservation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [openFocusIndex, setOpenFocusIndex] = React.useState(0);
  const [guestsValue, setGuestsValue] = React.useState<Guests>(
    filter?.guests || defaultGuestValue
  );
  const [totalGuests, setTotalGuests] = React.useState(
    filter?.totalGuests || 0
  );
  const [adultMembersGuestsTotalPrice, setAdultMembersGuestsTotalPrice] =
    React.useState(0);
  const [adultNonMembersGuestsTotalPrice, setAdultNonMembersGuestsTotalPrice] =
    React.useState(0);
  const [kidMembersGuestsTotalPrice, setKidMembersGuestsPrice] =
    React.useState(0);
  const [kidNonMembersGuestsTotalPrice, setKidNonMembersGuestsTotalPrice] =
    React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState<DateRange>(
    filter?.rangeDate || defaultSelectedDates
  );
  momentLocale();
  const windowSize = useWindowSize();
  const { openRoom, allRooms } = useRooms();

  const hotelBlockDates = React.useMemo(() => {
    if (blockDates?.length && openRoom) {
      const hotelBlockDates = blockDates?.filter((rangeDates) => {
        return openRoom?.hotel
          ? rangeDates?.hotels?.includes(openRoom?.hotel)
          : null;
      });
      const datesBetween = hotelBlockDates?.map((rangeDates) => {
        return enumerateDaysBetweenDates(
          moment(rangeDates?.startDate),
          moment(rangeDates?.endDate)
        );
      });
      return datesBetween.length
        ? datesBetween?.reduce((previousDates, currentDates) => [
            ...previousDates,
            ...currentDates,
          ])
        : [];
    }
    return [];
  }, [blockDates, openRoom]);

  const openRoomUnavaibleDates = openRoom?.unavaibleDates?.length
    ? openRoom?.unavaibleDates
    : [];

  const unavaibleDates = [...openRoomUnavaibleDates, ...hotelBlockDates];

  const [reservationNights, nonSeasonalNights, seasonalNights] =
    React.useMemo(() => {
      const totalDays =
        selectedDate.endDate?.diff(selectedDate.startDate, 'days') || 0;

      const seasonalNights: (moment.Moment | undefined)[] = [];
      const nonSeasonalNights: (moment.Moment | undefined)[] = [];

      const dates = range(0, totalDays)
        .map((date: number) => {
          const currentDate = selectedDate.startDate?.clone().add(date, 'days');
          const isSeasonal = seasonalDates?.some((seasonalDate) =>
            currentDate?.isBetween(seasonalDate.startDate, seasonalDate.endDate)
          );
          if (isSeasonal) seasonalNights.push(currentDate);
          if (!isSeasonal) nonSeasonalNights.push(currentDate);

          return currentDate?.toDate();
        })
        .flatMap((date) => (!!date ? [date] : []));

      return [dates, nonSeasonalNights.length, seasonalNights.length];
    }, [selectedDate, seasonalDates]);

  React.useEffect(() => {
    setAdultMembersGuestsTotalPrice(
      (guestsValue?.adultMembersGuests || 0) *
        (openRoom?.priceAssociate || 0) *
        nonSeasonalNights +
        (guestsValue?.adultMembersGuests || 0) *
          (openRoom?.priceAssociateSeasonal || 0) *
          seasonalNights
    );
    setAdultNonMembersGuestsTotalPrice(
      (guestsValue?.adultNonMembersGuests || 0) *
        (openRoom?.priceNoAssociate || 0) *
        nonSeasonalNights +
        (guestsValue?.adultNonMembersGuests || 0) *
          (openRoom?.priceNoAssociateSeasonal || 0) *
          seasonalNights
    );
    setKidMembersGuestsPrice(
      (guestsValue?.kidMembersGuests || 0) *
        (openRoom?.priceChildAssociate || 0) *
        nonSeasonalNights +
        (guestsValue?.kidMembersGuests || 0) *
          (openRoom?.priceChildSeasonal || 0) *
          seasonalNights
    );
    setKidNonMembersGuestsTotalPrice(
      (guestsValue?.kidNonMembersGuests || 0) *
        (openRoom?.priceChildNoAssociate || 0) *
        nonSeasonalNights +
        (guestsValue?.kidNonMembersGuests || 0) *
          (openRoom?.priceNoAssociateChildSeasonal || 0) *
          seasonalNights
    );
  }, [guestsValue, openRoom, nonSeasonalNights, seasonalNights]);

  const onChangeGuestValue = (guests: Guests) => {
    setTotalGuests(
      (guests?.adultMembersGuests || 0) +
        (guests?.adultNonMembersGuests || 0) +
        (guests?.kidMembersGuests || 0) +
        (guests?.kidNonMembersGuests || 0) +
        (guests?.babyGuests || 0)
    );
    setGuestsValue(guests);
  };

  React.useEffect(() => {
    setReservation({
      ...reservation,
      associateGuests: guestsValue.adultMembersGuests,
      noAssociateGuests: guestsValue.adultNonMembersGuests,
      childAssociateGuests: guestsValue.kidMembersGuests,
      childNoAssociateGuests: guestsValue.kidNonMembersGuests,
      babyGuests: guestsValue.babyGuests,
      totalGuests,
      checkInDate: selectedDate.startDate?.format('YYYY-MM-DD'),
      checkOutDate: selectedDate.endDate?.format('YYYY-MM-DD'),
      nights: nonSeasonalNights + seasonalNights,
      seasonalNights: seasonalNights,
      noSeasonalNights: nonSeasonalNights,
      total:
        adultMembersGuestsTotalPrice +
        adultNonMembersGuestsTotalPrice +
        kidMembersGuestsTotalPrice +
        kidNonMembersGuestsTotalPrice,
      dates: reservationNights,
      roomId: openRoom?.id,
      roomTotalRooms: openRoom?.totalRooms,
      adultMembersGuestsTotalPrice,
      adultNonMembersGuestsTotalPrice,
      kidMembersGuestsTotalPrice,
      kidNonMembersGuestsTotalPrice,
    });
  }, [
    openRoom,
    guestsValue,
    selectedDate,
    nonSeasonalNights,
    seasonalNights,
    totalGuests,
    adultMembersGuestsTotalPrice,
    adultNonMembersGuestsTotalPrice,
    kidMembersGuestsTotalPrice,
    kidNonMembersGuestsTotalPrice,
  ]);

  const isPreReservation = !!openRoom?.preReservation;

  const similarRooms = allRooms
    ?.filter((room) => {
      return room?.hotel == openRoom?.hotel;
    })
    .filter((_, i) => i < 4);

  const descriptionSplit = openRoom?.description?.split('\n');
  const rulesSplit = openRoom?.rules?.split('\n');

  const buttonRef = React.useMemo(() => {
    if (selectedDate?.endDate && selectedDate?.startDate && totalGuests > 0)
      return isPreReservation ? '/reserva' : '/pagamento';
    return pathname;
  }, [selectedDate, totalGuests]);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {openRoom?.title}
          </h2>
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {openRoom?.city} </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {openRoom?.maxGuests} <span>hóspedes</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {openRoom?.totalRooms} <span>quartos</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Informações</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {descriptionSplit?.map((item, index) => {
            return (
              <div key={`description_text_${index}`}>
                <span>{item}</span>
                <br />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSetioncontact = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Condições Excepcionais</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          Para quaisquer condições excepcionais favor entrar em contato pelo
          número:
        </div>
        <div className="text-neutral-800 dark:text-neutral-300">
          <strong>32902604</strong>
        </div>
      </div>
    );
  };

  const renderSection3 = (title: string, amenities: AmenitiesData) => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{title} </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {amenities?.data?.map((item) => (
            <div
              key={item.attributes.type}
              className="flex items-center space-x-3"
            >
              <i className={`text-3xl las ${item.attributes.icon}`}></i>
              <span className=" ">{item.attributes.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Regulamento de hospedagem</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">
            Regulamento {openRoom?.hotel}
          </h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              {rulesSplit?.map((item, index) => {
                return <li key={`description_text_${index}`}>{item}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">
            Horários de check-in e check-out
          </h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>{openRoom?.checkInTime}</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>{openRoom?.checkOutTime}</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Política de cancelamento</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div> */}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between flex-col">
          <span className="text-base font-normal text-neutral-500 dark:text-neutral-400">
            A partir de
          </span>
          <span className="text-3xl font-semibold">
            {formatMoney(openRoom?.priceAssociate || 0)}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /noite
            </span>
          </span>
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? 'left' : 'right'}
            unavaibleDates={unavaibleDates}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            maxGuests={openRoom?.maxGuests}
            totalGuests={totalGuests}
            onChange={(data) => onChangeGuestValue(data)}
            defaultValue={guestsValue}
          />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              {formatMoney(
                adultMembersGuestsTotalPrice +
                  adultNonMembersGuestsTotalPrice +
                  kidMembersGuestsTotalPrice +
                  kidNonMembersGuestsTotalPrice
              )}
            </span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary href={buttonRef}>Reservar</ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingStayDetailPage  ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={openRoom?.mainPhoto?.data?.attributes?.url}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {openRoom?.photos?.data
              ?.filter((_, i) => i >= 1 && i < 5)
              .map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 3 ? 'hidden sm:block' : ''
                  }`}
                >
                  <NcImage
                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item?.attributes?.url || ''}
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(index + 1)}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Ver todas as fotos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={openRoom?.photos}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row-reverse">
        {/* SIDEBAR */}
        <div className="block lg:hidden mb-14">{renderSection1()}</div>
        <div className="block flex-grow mb-14 lg:mt-0">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          <div className="hidden lg:block">{renderSection1()}</div>

          {renderSection2()}
          {renderSection8()}
          {renderSetioncontact()}
          {openRoom?.amenities &&
            renderSection3('Comodidades', openRoom?.amenities)}
          {openRoom?.room_amenities &&
            renderSection3('Comodidades do quarto', openRoom?.room_amenities)}
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
              $311
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /noite
              </span>
            </span>

            <ButtonPrimary href={buttonRef}>Reservar</ButtonPrimary>
          </div>
        </div>
      )}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridFeaturePlaces
              heading={`Mais opções em ${openRoom?.hotel}`}
              rooms={similarRooms}
              hotel={openRoom?.hotel || 'hoteis'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingStayDetailPage;
