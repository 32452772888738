import React from 'react';
import ButtonClose from 'shared/ButtonClose/ButtonClose';

export interface AlertProps {
  containerClassName?: string;
  type?: 'default' | 'warning' | 'info' | 'success' | 'error';
  onClose(): void;
}

const Alert: React.FC<AlertProps> = ({
  children = 'Alert Text',
  containerClassName = '',
  type = 'default',
  onClose,
}) => {
  let classes = containerClassName;
  switch (type) {
    case 'default':
      classes += ' text-black bg-neutral-900';
      break;
    case 'info':
      classes += ' bg-status-infoBg text-status-info';
      break;
    case 'success':
      classes += ' bg-status-successBg text-status-success';
      break;
    case 'error':
      classes += ' bg-error-50 text-error-200';
      break;
    case 'warning':
      classes += ' bg-status-warningBg text-status-warning';
      break;
    default:
      break;
  }

  return (
    <div
      className={`ttnc-alert relative flex items-center justify-center flex-col text-paragraph-base px-6 pt-4 pb-3 rounded-lg ${classes}`}
    >
      <i className="pe-7s-info text-2xl mr-2"></i>
      {children}
      <ButtonClose onClick={onClose} className="absolute top-1 right-1" />
    </div>
  );
};

export default Alert;
