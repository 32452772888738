import React from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';
import { useReservation } from 'hooks/reservation';
import { usePayment } from 'hooks/payment';
import { Reservation } from 'hooks/reservation/types';
import { useRooms } from 'hooks/rooms';
import moment from 'moment';
import momentLocale from 'helpers/momentLocale';
import { formatMoney } from 'helpers/formatMoney';

export interface PayPageProps {
  className?: string;
}

const PayPage: React.FC<PayPageProps> = ({ className = '' }) => {
  momentLocale();
  const { reservation, setReservation, reservationId } = useReservation();
  const { pixData } = usePayment();
  const { openRoom } = useRooms();
  const [reservationCopy, setReservationCopy] = React.useState<Reservation>({});

  const isPreReservation = !!openRoom?.preReservation;

  const qrCodeLink =
    pixData?.point_of_interaction?.transaction_data?.qr_code || '';

  const qrCodeBase64 =
    pixData?.point_of_interaction?.transaction_data?.qr_code_base64 || '';

  React.useEffect(() => {
    if (Object.keys(reservation).length) {
      setReservationCopy(JSON.parse(JSON.stringify(reservation)));
      setReservation({});
    }
  }, [reservation]);

  const reservationDate = React.useMemo(() => {
    const start = moment(reservationCopy.checkInDate).format('DD');
    const end = moment(reservationCopy.checkOutDate).format('DD MMM, YYYY');
    return `${start} - ${end}`;
  }, [reservationCopy]);

  const renderContent = () => {
    return (
      <div className="flex w-full flex-col space-y-8 border-neutral-200 px-0 dark:border-neutral-700 sm:rounded-2xl sm:border sm:p-6 xl:p-8">
        <h2 className="text-3xl font-semibold lg:text-4xl">
          Corra e confirme sua reserva! 🎉
        </h2>

        <span className="mt-1 block text-justify text-base font-medium sm:text-lg">
          Assim que seu pagamento for identificado, você receberá um e-mail com
          a confirmação de sua reserva. Verifique os dados abaixo para realizar
          o pagamento!
        </span>

        <span className="mt-1 block pb-3 text-justify text-base font-medium sm:text-lg">
          Atenção: o código PIX é válido por 24 horas.
        </span>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Dados do PIX</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="w-full flex-shrink-0 sm:w-48">
              <div className="aspect-w-4 aspect-h-3 overflow-hidden sm:aspect-h-4">
                <NcImage src={`data:image/jpeg;base64,${qrCodeBase64}`} />
              </div>
            </div>
            <div className="sm:h-48 sm:px-5">
              <div className="flex h-full flex-col">
                <span className="mt-1 block pb-3 text-justify text-base font-medium sm:text-lg">
                  Escaneie o QRCode ao lado ou copie o código abaixo para pagar
                  por meio de PIX Copia e Cola
                </span>
                <span className="break-all text-justify text-sm text-neutral-500 dark:text-neutral-400">
                  {qrCodeLink}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Sua reserva</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="w-full flex-shrink-0 sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 overflow-hidden rounded-2xl sm:aspect-h-4">
                <NcImage src={openRoom?.mainPhoto?.data?.attributes?.url} />
              </div>
            </div>
            <div className="space-y-3  pt-5 sm:px-5 sm:pb-5">
              <div>
                <span className="text-sm text-neutral-500 line-clamp-1 dark:text-neutral-400">
                  {openRoom?.city}
                </span>
                <span className="mt-1 block text-base font-medium sm:text-lg">
                  {openRoom?.title}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {openRoom?.totalRooms}{' '}
                <span>
                  {(openRoom?.totalRooms || 0) > 1 ? 'quartos' : 'quarto'}
                </span>
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            </div>
          </div>
          <div className="mt-6 flex flex-col divide-y divide-neutral-200 rounded-3xl border border-neutral-200 dark:divide-neutral-700 dark:border-neutral-700 sm:flex-row sm:divide-x sm:divide-y-0">
            <div className="flex flex-1 space-x-4 p-5">
              <svg
                className="h-8 w-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Data</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {reservationDate}
                </span>
              </div>
            </div>
            <div className="flex flex-1 space-x-4 p-5">
              <svg
                className="h-8 w-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Hóspedes</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {reservationCopy?.totalGuests}{' '}
                  {(reservationCopy?.totalGuests || 0) > 1
                    ? 'hóspedes'
                    : 'hóspede'}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Detalhes da reserva</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Código de reserva</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {reservationId}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Data</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {moment().format('DD MMM, YYYY')}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {formatMoney(reservationCopy?.totalWithInterest || 0, 2)}
              </span>
            </div>
            {!isPreReservation && (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Método de pagamento</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  PIX
                </span>
              </div>
            )}
          </div>
        </div>
        <div>
          <ButtonPrimary href="/pesquisa">Explore mais quartos</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="mx-auto max-w-4xl">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
