import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import NcInputNumber from 'components/NcInputNumber/NcInputNumber';
import { Guests } from 'hooks/filter/types';
import ClearDataButton from './ClearDataButton';

export interface GuestsInputProps {
  defaultValue: Guests;
  totalGuests: number;
  onChange: (data: Guests) => void;
  fieldClassName?: string;
  maxGuests?: number;
}

const GuestsInput: React.FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  maxGuests,
  totalGuests,
  fieldClassName = '[ nc-hero-field-padding ]',
}) => {
  const {
    adultMembersGuests,
    adultNonMembersGuests,
    kidMembersGuests,
    kidNonMembersGuests,
    babyGuests,
  } = defaultValue;

  React.useEffect(() => {
    if (onChange) {
      onChange({
        adultMembersGuests,
        adultNonMembersGuests,
        kidMembersGuests,
        kidNonMembersGuests,
        babyGuests,
      });
    }
  }, [
    adultMembersGuests,
    adultNonMembersGuests,
    kidMembersGuests,
    kidNonMembersGuests,
    babyGuests,
  ]);

  return (
    <Popover className="flex relative [ nc-flex-1 ]">
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? 'nc-hero-field-focused' : ''
            }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="nc-icon-field"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                {totalGuests || ''}{' '}
                {!totalGuests || totalGuests > 1 ? 'Hóspedes' : 'Hóspede'}
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                Adicionar hóspedes
              </span>
              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    onChange({
                      adultMembersGuests: 0,
                      adultNonMembersGuests: 0,
                      kidMembersGuests: 0,
                      kidNonMembersGuests: 0,
                      babyGuests: 0,
                    });
                  }}
                />
              )}
            </div>
          </Popover.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={adultMembersGuests}
                onChange={(value) =>
                  onChange({
                    ...defaultValue,
                    adultMembersGuests: value,
                  })
                }
                max={
                  maxGuests && totalGuests >= maxGuests
                    ? adultMembersGuests
                    : maxGuests
                }
                min={0}
                label="Adulto Sócios"
              />
              <NcInputNumber
                className="w-full"
                defaultValue={adultNonMembersGuests}
                onChange={(value) =>
                  onChange({
                    ...defaultValue,
                    adultNonMembersGuests: value,
                  })
                }
                max={
                  maxGuests && totalGuests >= maxGuests
                    ? adultNonMembersGuests
                    : maxGuests
                }
                min={0}
                label="Adultos Não-sócios"
              />
              <NcInputNumber
                className="w-full"
                defaultValue={kidMembersGuests}
                onChange={(value) =>
                  onChange({
                    ...defaultValue,
                    kidMembersGuests: value,
                  })
                }
                max={
                  maxGuests && totalGuests >= maxGuests
                    ? kidMembersGuests
                    : maxGuests
                }
                min={0}
                label="Crianças* Sócias"
              />
              <NcInputNumber
                className="w-full"
                defaultValue={kidNonMembersGuests}
                onChange={(value) =>
                  onChange({
                    ...defaultValue,
                    kidNonMembersGuests: value,
                  })
                }
                max={
                  maxGuests && totalGuests >= maxGuests
                    ? kidNonMembersGuests
                    : maxGuests
                }
                min={0}
                label="Crianças* Não-sócias"
              />
              <div className="my-3">
                <p className="text-sm">*Crianças entre 4 e 12 anos</p>
              </div>
              <NcInputNumber
                className="w-full"
                defaultValue={babyGuests}
                onChange={(value) =>
                  onChange({
                    ...defaultValue,
                    babyGuests: value,
                  })
                }
                max={
                  maxGuests && totalGuests >= maxGuests ? babyGuests : maxGuests
                }
                min={0}
                label="Crianças abaixo de 4 anos"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
