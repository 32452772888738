import moment from 'moment';

export const enumerateDaysBetweenDates = function (
  startDate: moment.Moment,
  endDate: moment.Moment
) {
  const now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(moment(now.format('YYYY-MM-DD')));
    now.add(1, 'days');
  }
  return dates;
};
