// import { getDate } from 'utils/getDate';
import React from 'react';
import { Filter } from './types';

type FilterContextData = {
  filter: Filter | null;
  setFilter(filter: Filter): void;
};

export const FilterContext = React.createContext({} as FilterContextData);

export interface FilterProviderProps {
  children: React.ReactNode;
}

const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [filter, setFilter] = React.useState<Filter | null>(null);
  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = React.useContext(FilterContext);

  return context;
};

export default FilterProvider;
