import React from 'react';
import { IMaskInput } from 'react-imask';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  mask?: string;
  inputRef?: any;
  error?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = '',
      sizeClass = 'h-11 px-4 py-3',
      fontClass = 'text-sm font-normal',
      rounded = 'rounded-2xl',
      type = 'text',
      mask = '',
      inputRef,
      error,
      ...args
    },
    ref
  ) => {
    return (
      <IMaskInput
        ref={ref}
        type={type}
        mask={mask}
        inputRef={inputRef}
        className={`block w-full border-neutral-200 focus:border-primary-6000 focus:ring focus:ring-primary-6000 focus:ring-opacity-80 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className} ${
          error ? 'border-error-100' : ''
        }`}
        {...args}
      />
    );
  }
);

Input.displayName = 'Input';

export default Input;
