import React from 'react';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import { Link } from 'react-router-dom';
import { Room } from 'hooks/rooms/types';
import { parseHotelToUrl } from 'utils/parseUrl';

export interface StayCardProps {
  className?: string;
  ratioClass?: string;
  size?: 'default' | 'small';
  room?: Room;
}

const StayCard: React.FC<StayCardProps> = ({
  size = 'default',
  className = '',
  room,
  ratioClass,
}) => {
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${room?.id}`}
          ratioClass={ratioClass}
          mainImg={room?.mainPhoto}
          galleryImgs={room?.photos}
          url={`/quarto/${parseHotelToUrl(room?.hotel)}/${room?.id}`}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === 'default' ? 'p-4 space-y-4' : 'p-3 space-y-2'}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {room?.hotel} · {room?.maxGuests} hospedes
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === 'default' ? 'text-lg' : 'text-base'
              }`}
            >
              <span className="line-clamp-1">{room?.title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === 'default' && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{room?.city}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {'R$'}
            {room?.priceAssociate}{' '}
            {size === 'default' && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /noite
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`/quarto/${parseHotelToUrl(room?.hotel)}/${room?.id}`}>
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCard;
