import React from 'react';
import Logo from 'shared/Logo/Logo';
import SocialsList from 'shared/SocialsList/SocialsList';
import { CustomLink } from 'data/types';
import { NavLink } from 'react-router-dom';

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: '1',
    title: 'Links',
    menus: [
      { href: '/', label: 'Principal' },
      {
        href: 'https://www.clubedosoficiais.org.br/apresentacao/',
        label: 'Sobre nós',
        targetBlank: true,
      },
      {
        href: 'https://www.clubedosoficiais.org.br/contato/',
        label: 'Entre em contato',
        targetBlank: true,
      },
      {
        href: 'https://www.clubedosoficiais.org.br/',
        label: 'Voltar para o clube',
        targetBlank: true,
      },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm grid place-items-center md:block">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4 text-center md:text-left">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {item.targetBlank ? (
                <a
                  href={item.href}
                  className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                >
                  {item.label}
                </a>
              ) : (
                <NavLink
                  exact
                  strict
                  target={item.targetBlank ? '_blank' : undefined}
                  rel="noopener noreferrer"
                  className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                  to={{
                    pathname: item.href || undefined,
                  }}
                >
                  {item.label}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-32 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-1 gap-y-10 gap-x-5 sm:gap-x-8 lg:grid-cols-3 lg:gap-x-20 ">
        <div className="justify-center grid gap-5 md:justify-start md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1 justify-center">
            <Logo imgClassName="max-h-40" />
          </div>
        </div>
        <div className="flex flex-col place-items-center gap-5 md:col-span-1 md:place-items-start">
          <h3 className="font-semibold text-neutral-700 dark:text-neutral-200">
            Entre em contato
          </h3>
          <h4 className="text-neutral-700 dark:text-neutral-200">
            hoteis@clubedosoficiais.org.br
          </h4>
          <h4 className="font-semibold text-neutral-700 dark:text-neutral-200">
            (31) 3290-2600
          </h4>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList className="flex items-center space-x-3 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
