// import { getDate } from 'utils/getDate';
import { Filter } from 'hooks/filter/types';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { api } from 'services/api';
import {
  parseUrlFilterRoom,
  parseUrlFilterRooms,
} from 'utils/parseUrlFilterRooms';
import { Room, RoomResponse, RoomsResponse } from './types';

type RoomsContextData = {
  allRooms?: Room[];
  allRoomsLoading: boolean;
  openRoom?: Room | null;
  openRoomLoading: boolean;
  setOpenRoomId(id: string): void;
  roomsFilter: Filter | null;
  setRoomsFilter(filter: Filter): void;
  filteredRoomsLoading: boolean;
  filteredRooms?: Room[];
};

export const RoomsContext = React.createContext({} as RoomsContextData);

export interface RoomsProviderProps {
  children: React.ReactNode;
}

export const fetchAllRooms = async () => {
  const roomsUrl = 'api/rooms';
  const populateRooms = '?populate=*';
  const { data: roomsResponse } = await api.get<RoomsResponse>(
    `${roomsUrl}${populateRooms}`
  );
  const roomsData = roomsResponse.data.map((roomResponse) => {
    return { ...roomResponse.attributes, id: roomResponse.id, avaible: true };
  });
  return roomsData;
};

export const fetchRoom = async (id: string | null) => {
  if (id) {
    const roomUrl = 'api/rooms';
    const populateRooms = parseUrlFilterRoom();
    const { data: roomResponse } = await api.get<RoomResponse>(
      `${roomUrl}/${id}?${populateRooms}`
    );
    const roomsAvailabilities =
      roomResponse?.data?.attributes?.room_availabilities?.data;
    const unavaibleDates = roomsAvailabilities?.length
      ? roomsAvailabilities
          .map((item) => {
            return moment(item.attributes.date);
          })
          .sort((a, b) => a.diff(b))
      : [];
    const roomData = {
      ...roomResponse.data.attributes,
      id: roomResponse.data.id,
      unavaibleDates,
    };
    return roomData;
  }
  return null;
};

export const fetchFilteredRooms = async (filter: Filter | null) => {
  const roomsUrl = 'api/rooms';
  const filterUrl = parseUrlFilterRooms(filter);
  const searchUrl = filter ? `${roomsUrl}?${filterUrl}` : roomsUrl;

  const { data: roomsResponse } = await api.get<RoomsResponse>(searchUrl);

  const roomsData = roomsResponse.data.map((roomResponse) => {
    const avaible =
      roomResponse?.attributes?.room_availabilities?.data?.length > 0
        ? false
        : true;
    return { ...roomResponse.attributes, id: roomResponse.id, avaible };
  });
  return roomsData;
};

const useRoom = (id: string | null) => {
  return useQuery(
    ['getRoom', id],
    () => {
      return fetchRoom(id);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

const RoomsProvider: React.FC<RoomsProviderProps> = ({ children }) => {
  const [openRoomId, setOpenRoomId] = React.useState<string | null>(null);
  const [roomsFilter, setRoomsFilter] = React.useState<Filter | null>(null);

  const { data: allRooms, isLoading: allRoomsLoading } = useQuery(
    'getAllRooms',
    () => {
      return fetchAllRooms();
    },
    {
      refetchOnMount: true,
      staleTime: 86400000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: filteredRooms, isLoading: filteredRoomsLoading } = useQuery(
    ['getFilteredRooms', roomsFilter],
    () => {
      return fetchFilteredRooms(roomsFilter);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: openRoom, isLoading: openRoomLoading } = useRoom(openRoomId);

  return (
    <RoomsContext.Provider
      value={{
        allRooms,
        openRoom,
        allRoomsLoading,
        openRoomLoading,
        setOpenRoomId,
        roomsFilter,
        setRoomsFilter,
        filteredRooms,
        filteredRoomsLoading,
      }}
    >
      {children}
    </RoomsContext.Provider>
  );
};

export const useRooms = () => {
  const context = React.useContext(RoomsContext);

  return context;
};

export default RoomsProvider;
