import React from 'react';
import { StayDataType } from 'data/types';
import HeaderFilter from './HeaderFilter';
import StayCard from 'components/StayCard/StayCard';
import { Room } from 'hooks/rooms/types';

export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: React.ReactNode;
  subHeading?: React.ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  rooms?: Room[];
  hotel: string;
}

const SectionGridFeaturePlaces: React.FC<SectionGridFeaturePlacesProps> = ({
  gridClass = '',
  heading = '',
  subHeading = '',
  rooms,
  hotel,
}) => {
  const renderCard = (room: Room) => {
    return <StayCard key={room.id} room={room} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter subHeading={subHeading} heading={heading} hotel={hotel} />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {rooms?.map((room) => renderCard(room))}
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
