import React from 'react';
import Logo from 'shared/Logo/Logo';
import Navigation from 'shared/Navigation/Navigation';
import MenuBar from 'shared/MenuBar/MenuBar';

export interface NavBarProps {
  isTop: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ isTop }) => {
  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? 'onTop ' : 'notOnTop backdrop-filter'
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo imgClassName="max-h-14" />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          {/* <div className="hidden items-center lg:flex space-x-1">
            <SearchDropdown />
          </div> */}
          <div className="flex items-center lg:hidden">
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
