import { NavItemType } from 'shared/Navigation/NavigationItem'; //MegamenuItem,
import ncNanoId from 'utils/ncNanoId';

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: '/',
    name: 'Principal',
  },
  {
    id: ncNanoId(),
    href: 'https://www.clubedosoficiais.org.br/apresentacao/',
    name: 'Sobre nós',
    targetBlank: true,
  },
  {
    id: ncNanoId(),
    href: 'https://www.clubedosoficiais.org.br/contato/',
    name: 'Entre em contato',
    targetBlank: true,
  },
  {
    id: ncNanoId(),
    href: 'https://www.clubedosoficiais.org.br/',
    name: 'Voltar para o clube',
    targetBlank: true,
  },
];
