import React from 'react';
import 'react-dates/initialize';
import StaySearchForm from './StaySearchForm';

export interface HeroSearchFormProps {
  className?: string;
}

const HeroSearchForm: React.FC<HeroSearchFormProps> = ({ className = '' }) => {
  const renderForm = () => {
    return <StaySearchForm />;
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
