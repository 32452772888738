import React from 'react';
import NavBar from './NavBar';
import { Helmet } from 'react-helmet';

const Header: React.FC = () => {
  const [isTop, setisTop] = React.useState(true);

  React.useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);

  function scrollFunction() {
    const $head = document.getElementById('nc-chifis-header');
    if (!$head) return;
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      !!isTop && setisTop(false);
    } else {
      setisTop(true);
    }
  }

  return (
    <div
      id="nc-chifis-header"
      className="nc-Header bg-white lg:sticky lg:top-0 w-full lg:left-0 lg:right-0 z-40"
    >
      <Helmet>
        <title>Hotéis Clube dos Oficiais Militares Mineiros</title>
        <script src="https://sdk.mercadopago.com/js/v2"></script>
      </Helmet>

      {/* NAV */}
      <NavBar isTop={isTop} />
    </div>
  );
};

export default Header;
