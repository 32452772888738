import React from 'react';
import NcImage from 'shared/NcImage/NcImage';
import { Link } from 'react-router-dom';
import convertNumbThousand from 'utils/convertNumbThousand';
import { Hotel } from 'hooks/hotels/types';
import { parseHotelToUrl } from 'utils/parseUrl';

export interface CardCategory5Props {
  size?: 'default' | 'small';
  className?: string;
  taxonomy: Hotel;
}

const CardCategory5: React.FC<CardCategory5Props> = ({
  size = 'default',
  className = '',
  taxonomy,
}) => {
  const { name, mainPhoto, totalRooms, city, title } = taxonomy;
  return (
    <Link
      to={`/pesquisa/${parseHotelToUrl(name)}`}
      className={`nc-CardCategory5 flex flex-col ${className}`}
      data-nc-id="CardCategory5"
    >
      <div
        className={
          'group aspect-w-4 aspect-h-3 relative h-0 w-full flex-shrink-0 overflow-hidden rounded-2xl'
        }
      >
        <NcImage
          src={mainPhoto?.data?.attributes?.url}
          className="h-full w-full rounded-2xl object-cover"
        />
        <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 transition-opacity group-hover:opacity-100"></span>
      </div>
      <div className="mt-4 truncate px-3">
        <h2
          className={
            'truncate text-base font-medium text-neutral-900 dark:text-neutral-100 sm:text-lg'
          }
        >
          {title}
        </h2>
        <div className="flex items-center space-x-2 text-sm text-neutral-500 dark:text-neutral-400">
          {size === 'default' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
          <span className="">{city}</span>
        </div>
        <span
          className={
            'mt-2 block text-sm text-neutral-6000 dark:text-neutral-400'
          }
        >
          {convertNumbThousand(totalRooms || 0)} quartos
        </span>
      </div>
    </Link>
  );
};

export default CardCategory5;
