import accounting from 'accounting-js';

export const formatMoney = (value: number, precision?: number) => {
  if (!value) return '-';
  return accounting.formatMoney(value, {
    symbol: 'R$',
    format: '%s%v',
    precision: precision || 0,
    thousand: '.',
    decimal: ',',
  });
};
