import React from 'react';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const Input = React.forwardRef<HTMLTextAreaElement, InputProps>(
  (
    {
      className = '',
      sizeClass = 'h-11 px-4 py-3',
      fontClass = 'text-sm font-normal',
      rounded = 'rounded-2xl',
      ...args
    },
    ref
  ) => {
    return (
      <textarea
        ref={ref}
        className={`block scrollbar-none w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
        {...args}
      />
    );
  }
);

Input.displayName = 'Input';

export default Input;
