import React from 'react';
import { SocialType } from 'shared/SocialsShare/SocialsShare';
import { NavLink } from 'react-router-dom';

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
}

const socials: SocialType[] = [
  {
    name: 'Facebook',
    icon: 'lab la-facebook-square',
    href: 'https://www.facebook.com/copmmg/',
  },
  {
    name: 'Instagram',
    icon: 'lab la-instagram',
    href: 'https://www.instagram.com/copmmg/',
  },
];

const SocialsList: React.FC<SocialsListProps> = ({
  className = '',
  itemClass = 'block',
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item, i) => (
        <NavLink
          exact
          strict
          key={i}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
          className={`${itemClass}`}
          to={{
            pathname: item.href || undefined,
          }}
        >
          <i className={item.icon}></i>
        </NavLink>
      ))}
    </nav>
  );
};

export default SocialsList;
