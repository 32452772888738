import React from 'react';
import StayCard from 'components/StayCard/StayCard';
import { StayDataType } from 'data/types';
// import Pagination from 'shared/Pagination/Pagination';
import { Room } from 'hooks/rooms/types';

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  rooms?: Room[];
}

const SectionGridFilterCard: React.FC<SectionGridFilterCardProps> = ({
  className = '',
  rooms,
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {rooms?.map((room) => (
          <StayCard key={room.id} room={room} />
        ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
