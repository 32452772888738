// import { getDate } from 'utils/getDate';
import React from 'react';
import { useQuery } from 'react-query';
import { api } from 'services/api';
import { Hotel, HotelsResponse } from './types';

type HotelsContextData = {
  allHotels?: Hotel[];
  allHotelsLoading: boolean;
};

export const HotelsContext = React.createContext({} as HotelsContextData);

export interface HotelsProviderProps {
  children: React.ReactNode;
}

export const fetchAllHotels = async () => {
  const hotelsUrl = 'api/hotels';
  const populateHotels =
    '?populate[mainPhoto][fields]=url&populate[photos][fields]=url&populate[mainMedia][fields]=url';
  const { data: hotelsResponse } = await api.get<HotelsResponse>(
    `${hotelsUrl}${populateHotels}`
  );
  const hotelsData = hotelsResponse.data.map((hotelResponse) => {
    return { ...hotelResponse.attributes, id: hotelResponse.id };
  });
  return hotelsData;
};

const HotelsProvider: React.FC<HotelsProviderProps> = ({ children }) => {
  const { data: allHotels, isLoading: allHotelsLoading } = useQuery(
    'getAllHotels',
    () => {
      return fetchAllHotels();
    },
    {
      staleTime: 86400000,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <HotelsContext.Provider
      value={{
        allHotels,
        allHotelsLoading,
      }}
    >
      {children}
    </HotelsContext.Provider>
  );
};

export const useHotels = () => {
  const context = React.useContext(HotelsContext);

  return context;
};

export default HotelsProvider;
