export const parseHotelToUrl = (hotel?: string) => {
  if (hotel == 'Carapebus') return 'carapebus';
  else if (hotel == 'Boa Esperança') return 'boaesperanca';
  else if (hotel == 'Hotel de Trânsito') return 'hoteldetransito';
  else return hotel;
};

export const parseUrlToHotel = (url?: string) => {
  if (url == 'carapebus') return 'Carapebus';
  else if (url == 'boaesperanca') return 'Boa Esperança';
  else if (url == 'hoteldetransito') return 'Hotel de Trânsito';
  else return url;
};
