import { Filter } from 'hooks/filter/types';
import moment from 'moment';
import qs from 'qs';

const parseDateSearch = (
  checkInDate: moment.Moment | null,
  checkOutDate: moment.Moment | null
) => {
  if (checkInDate && checkOutDate)
    return {
      room_availabilities: {
        filters: {
          $and: [
            {
              date: {
                $lte: checkOutDate.format('YYYY-MM-DD'),
              },
            },
            {
              date: {
                $gte: checkInDate.format('YYYY-MM-DD'),
              },
            },
            {
              availableRooms: {
                $eq: 0,
              },
            },
          ],
        },
      },
    };
  else if (checkInDate)
    return {
      room_availabilities: {
        filters: {
          $and: [
            {
              date: {
                $gte: checkInDate.format('YYYY-MM-DD'),
              },
            },
            {
              availableRooms: {
                $eq: 0,
              },
            },
          ],
        },
      },
    };
  else if (checkOutDate)
    return {
      room_availabilities: {
        filters: {
          $and: [
            {
              date: {
                $lte: checkOutDate.format('YYYY-MM-DD'),
              },
            },
            {
              availableRooms: {
                $eq: '0',
              },
            },
          ],
        },
      },
    };
  else return undefined;
};

export const parseUrlFilterRooms = (filter: Filter | null) => {
  const possibleHotels = ['Carapebus', 'Boa Esperança', 'Hotel de Trânsito'];
  const guestsSearch = filter?.totalGuests || 0;
  const hotelSearch =
    filter?.hotel && possibleHotels.includes(filter?.hotel)
      ? {
          hotel: {
            $eq: filter?.hotel,
          },
        }
      : undefined;
  const dateSearch = parseDateSearch(
    filter?.checkInDate || null,
    filter?.checkOutDate || null
  );

  const filterSearch = {
    filters: {
      $and: [
        {
          maxGuests: {
            $gte: guestsSearch,
          },
        },
        hotelSearch,
      ],
    },
    populate: {
      mainPhoto: {
        fields: 'url',
      },
      photos: {
        fields: 'url',
      },
      amenities: {
        fields: ['type', 'name', 'icon'],
      },
      room_amenities: {
        fields: ['type', 'name', 'icon'],
      },
      ...dateSearch,
    },
  };
  return qs.stringify(filterSearch);
};

export const parseUrlFilterRoom = () => {
  const filterSearch = {
    populate: {
      mainPhoto: {
        fields: 'url',
      },
      photos: {
        fields: 'url',
      },
      amenities: {
        fields: ['type', 'name', 'icon'],
      },
      room_amenities: {
        fields: ['type', 'name', 'icon'],
      },
      room_availabilities: {
        filters: {
          availableRooms: {
            $eq: '0',
          },
        },
      },
    },
  };
  return qs.stringify(filterSearch);
};

export const parseUrlFilterRoomInDate = (
  checkIn?: string,
  checkOut?: string
) => {
  const filterSearch = {
    populate: {
      mainPhoto: {
        fields: 'url',
      },
      photos: {
        fields: 'url',
      },
      amenities: {
        fields: ['type', 'name', 'icon'],
      },
      room_amenities: {
        fields: ['type', 'name', 'icon'],
      },
      room_availabilities: {
        filters: {
          $and: [
            {
              availableRooms: {
                $eq: '0',
              },
            },
            {
              date: {
                $lte: checkOut,
              },
            },
            {
              date: {
                $gte: checkIn,
              },
            },
          ],
        },
      },
    },
  };
  return qs.stringify(filterSearch);
};
