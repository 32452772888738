import Glide from '@glidejs/glide';
import { Media } from 'hooks/rooms/types';
import React from 'react';
import { Link } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';

export interface GallerySliderProps {
  className?: string;
  imageClass?: string;
  containerImageClass?: string;
  galleryClass?: string;
  galleryImgs?: { data: Media[] };
  mainImg?: { data: Media };
  ratioClass?: string;
  uniqueID: string;
  url?: string;
}

const GallerySlider: React.FC<GallerySliderProps> = ({
  className = '',
  galleryClass,
  galleryImgs,
  mainImg,
  ratioClass = 'aspect-w-4 aspect-h-3',
  containerImageClass,
  imageClass,
  uniqueID = 'uniqueID',
  url = '',
}) => {
  const imagesData =
    galleryImgs?.data && mainImg?.data
      ? [mainImg?.data, ...galleryImgs?.data]
      : galleryImgs?.data;
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}`;

  const MY_GLIDEJS = React.useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
      autoplay: 7000,
    });
  }, [UNIQUE_CLASS]);

  React.useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderSliderGallery = () => {
    return (
      <div
        className={`${UNIQUE_CLASS} relative group overflow-hidden ${galleryClass}`}
      >
        <Link to={url}>
          <div className={`glide__track ${galleryClass}`} data-glide-el="track">
            <ul className={`glide__slides ${galleryClass}`}>
              {imagesData?.length ? (
                imagesData.map((item, index) => (
                  <li key={index} className={`glide__slides ${galleryClass}`}>
                    <div className={ratioClass}>
                      <NcImage
                        containerClassName={containerImageClass}
                        className={imageClass}
                        src={item?.attributes.url}
                        loading={index === 0 ? 'eager' : 'lazy'}
                      />
                    </div>
                  </li>
                ))
              ) : (
                <li key={'none'} className={`glide__slides ${galleryClass}`}>
                  <div className={ratioClass}>
                    <NcImage
                      containerClassName={containerImageClass}
                      className={imageClass}
                      src={''}
                    />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
