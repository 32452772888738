import Glide from '@glidejs/glide';
import { Media } from 'hooks/rooms/types';
import React from 'react';
import { Link } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import NextPrev from 'shared/NextPrev/NextPrev';

export interface GallerySliderProps {
  className?: string;
  galleryImgs?: { data: Media[] };
  mainImg?: { data: Media };
  ratioClass?: string;
  uniqueID: string;
  url?: string;
}

const GallerySlider: React.FC<GallerySliderProps> = ({
  className = '',
  galleryImgs,
  mainImg,
  ratioClass = 'aspect-w-4 aspect-h-3',
  uniqueID = 'uniqueID',
  url = '',
}) => {
  const imagesData = galleryImgs?.data
    ? [mainImg?.data, ...galleryImgs?.data]
    : [mainImg?.data];
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}`;

  const MY_GLIDEJS = React.useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
    });
  }, [UNIQUE_CLASS]);

  React.useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {imagesData?.length ? (
          imagesData
            ?.slice(0, 3)
            .map((_, i) => (
              <button
                className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
                key={i}
                data-glide-dir={`=${i}`}
              />
            ))
        ) : (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={'none'}
            data-glide-dir={`=none`}
          />
        )}
      </div>
    );
  };

  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        <Link to={url}>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {imagesData?.length ? (
                imagesData.slice(0, 3).map((item, index) => (
                  <li key={index} className="glide__slide">
                    <div className={ratioClass}>
                      <NcImage src={item?.attributes.url} />
                    </div>
                  </li>
                ))
              ) : (
                <li key={'none'} className="glide__slide">
                  <div className={ratioClass}>
                    <NcImage src={''} />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </Link>

        {/* DOTS */}
        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
        {renderDots()}

        {/* NAV */}
        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
