import React from 'react';

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  sizeClass?: string;
  error?: boolean;
}

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  ({ className = '', sizeClass = 'h-11', children, error, ...args }, ref) => {
    return (
      <select
        className={`nc-Select ${sizeClass} ${className} block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${
          error ? 'border-error-100' : ''
        }`}
        ref={ref as any}
        {...args}
      >
        {children}
      </select>
    );
  }
);
Select.displayName = 'Select';

export default Select;
