import React from 'react';
import Heading from 'shared/Heading/Heading';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { parseHotelToUrl } from 'utils/parseUrl';

export interface HeaderFilterProps {
  heading: React.ReactNode;
  subHeading?: React.ReactNode;
  hotel: string;
}

const HeaderFilter: React.FC<HeaderFilterProps> = ({
  subHeading = '',
  heading = '🎈 Latest Articles',
  hotel,
}) => {
  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between">
        <span className="hidden sm:block flex-shrink-0">
          <ButtonSecondary
            className="!leading-none"
            href={`/pesquisa/${parseHotelToUrl(hotel)}`}
          >
            <span>Ver todos</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;
