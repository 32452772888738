import { Filter } from 'hooks/filter/types';
import moment from 'moment';
import qs from 'qs';
import { parseHotelToUrl, parseUrlToHotel } from './parseUrl';

interface urlParams {
  hotel: string;
  searchParams: string;
}

const guestsFilterParser = {
  adultMembersGuests: 'sociosadultos',
  adultNonMembersGuests: 'naosociosadultos',
  kidMembersGuests: 'socioscriancas',
  kidNonMembersGuests: 'naosocioscriancas',
  babyGuests: 'bebes',
};

const objectKeys = <TObj>(obj: TObj): (keyof TObj)[] =>
  Object.keys(obj) as (keyof TObj)[];

export const parseFilterToUrl = (filter: Filter | null) => {
  if (filter) {
    const hotelFilter = filter.hotel
      ? `/pesquisa/${parseHotelToUrl(filter.hotel)}?`
      : '/pesquisa/hoteis?';
    const totalGuestFilter = filter.totalGuests
      ? `hospedes=${filter.totalGuests}&`
      : '';
    const guestsFilter = objectKeys(filter.guests || {}).reduce((acc, key) => {
      if (filter.guests)
        acc += `${guestsFilterParser[key]}=${filter.guests[key]}&`;
      return acc;
    }, '');
    const checkInDateFilter = filter.checkInDate
      ? `checkin=${filter.checkInDate.format('YYYY-MM-DD')}&`
      : '';
    const checkOutDateFilter = filter.checkOutDate
      ? `checkout=${filter.checkOutDate.format('YYYY-MM-DD')}&`
      : '';
    return `${hotelFilter}${totalGuestFilter}${guestsFilter}${checkInDateFilter}${checkOutDateFilter}`.slice(
      0,
      -1
    );
  }
  return '/pesquisa/hoteis';
};

export const parseUrlToFilter = ({
  hotel,
  searchParams,
}: urlParams): Filter => {
  const possibleHotels = ['Carapebus', 'Boa Esperança', 'Hotel de Trânsito'];
  const hotelParsed = parseUrlToHotel(hotel);
  const hotelFilter =
    hotelParsed && possibleHotels.includes(hotelParsed)
      ? hotelParsed
      : undefined;
  const filters = qs.parse(searchParams);
  return {
    hotel: hotelFilter,
    checkInDate: filters?.checkin ? moment(filters.checkin as string) : null,
    checkOutDate: filters?.checkout ? moment(filters.checkout as string) : null,
    totalGuests: Number(filters?.hospedes as string) || undefined,
    guests: {
      adultMembersGuests: Number(filters?.sociosadultos) || 0,
      adultNonMembersGuests: Number(filters?.naosociosadultos) || 0,
      kidMembersGuests: Number(filters?.socioscriancas) || 0,
      kidNonMembersGuests: Number(filters?.naosocioscriancas) || 0,
      babyGuests: Number(filters?.bebes) || 0,
    },
  };
};
