import React from 'react';
import { Link } from 'react-router-dom';
// import logoImg from 'images/logo.png';
// import logoLightImg from 'images/logo-light.png';
// import LogoSvg from './LogoSvg';

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  svgClassName?: string;
  imgClassName?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = 'https://www.clubedosoficiais.org.br/wp-content/uploads/2021/09/cropped-Logo-COMM-1.png',
  imgLight = 'https://www.clubedosoficiais.org.br/wp-content/uploads/2021/09/cropped-Logo-COMM-1.png',
  className = '',
  imgClassName,
  // svgClassName,
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      {/* <LogoSvg className={svgClassName} /> */}

      {img ? (
        <img
          className={`block ${imgClassName} ${imgLight ? 'dark:hidden' : ''}`}
          src={img}
          alt="Logo"
        />
      ) : (
        'Logo Here'
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
