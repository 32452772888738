import axios from 'axios';
import { QueryClient } from 'react-query';

const STRAPI_URL = process.env.REACT_APP_STRAPI_URL;
const STRAPI_API_KEY = process.env.REACT_APP_STRAPI_API_KEY;
const COMM_API_URL = process.env.REACT_APP_COMM_API_URL;

const queryClient = new QueryClient();

const api = axios.create({
  baseURL: STRAPI_URL,
});

const apiStrapi = axios.create({
  baseURL: STRAPI_URL,
  headers: {
    Authorization: STRAPI_API_KEY as string,
  },
});

const apiComm = axios.create({
  baseURL: COMM_API_URL,
});

export { api, apiStrapi, queryClient, apiComm };
