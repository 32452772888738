import React from 'react';
import {
  AnchorDirectionShape,
  DateRangePicker,
  FocusedInputShape,
} from 'react-dates';
import { DateRange } from 'hooks/filter/types';

import useWindowSize from 'hooks/useWindowResize';
import moment from 'moment';

export interface StayDatesRangeInputProps {
  defaultValue: DateRange;
  defaultFocus?: FocusedInputShape | null;
  onChange?: (data: DateRange) => void;
  onFocusChange?: (focus: FocusedInputShape | null) => void;
  fieldClassName?: string;
  wrapClassName?: string;
  numberOfMonths?: number;
  anchorDirection?: AnchorDirectionShape;
  unavaibleDates?: moment.Moment[];
}

const StayDatesRangeInput: React.FC<StayDatesRangeInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = null,
  onFocusChange,
  fieldClassName = '[ nc-hero-field-padding ]',
  wrapClassName = 'divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 md:border-l md:border-r border-neutral-200 dark:border-neutral-700 lg:border-none',
  numberOfMonths,
  anchorDirection,
  unavaibleDates,
}) => {
  const [focusedInput, setFocusedInput] = React.useState(defaultFocus);
  const [stateDate, setStateDate] = React.useState(defaultValue);
  const [noRangeDate, setNoRangeDate] = React.useState(false);

  const windowSize = useWindowSize();

  React.useEffect(() => {
    setStateDate(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  React.useEffect(() => {
    if (onChange) {
      onChange(stateDate);
    }
  }, [stateDate]);

  const onChangeState = (dateRange: DateRange) => {
    if (unavaibleDates?.length) {
      const noDates = unavaibleDates.filter((date) => {
        if (
          (date.isBefore(dateRange.endDate) &&
            date.isAfter(dateRange.startDate)) ||
          date.isSame(dateRange.startDate) ||
          date.isSame(dateRange.endDate)
        )
          return true;
      });
      if (dateRange?.startDate && dateRange?.endDate && noDates?.length)
        return undefined;
      else setStateDate(dateRange);
    } else setStateDate(dateRange);
  };

  const isBlockedDay = (day: moment.Moment) => {
    const isUnavaible = unavaibleDates?.filter((date) => {
      return date.format('YYYY-MM-DD') == day.format('YYYY-MM-DD');
    });
    if (isUnavaible && isUnavaible?.length > 0) return true;
    return false;
  };

  const isOutsideRange = (day: moment.Moment) => {
    if (day.isBefore(moment())) return true;
    if (stateDate?.startDate) {
      const isAfterStart = unavaibleDates?.filter((date) => {
        return date.isAfter(stateDate?.startDate);
      });
      if (
        isAfterStart &&
        isAfterStart?.length > 0 &&
        day.isAfter(isAfterStart?.[0])
      )
        return true;
      return false;
    }
    return false;
  };

  const handleDateFocusChange = (focus: FocusedInputShape | null) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
    if (!focus && !(stateDate?.startDate && stateDate?.endDate))
      setNoRangeDate(true);
    if (focus == 'startDate' && noRangeDate)
      setStateDate({ startDate: null, endDate: null });
  };

  const renderInputCheckInDate = () => {
    const focused = focusedInput === 'startDate';
    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} flex-shrink-0 items-center space-x-3 cursor-pointer ${
          focused ? 'nc-hero-field-focused' : ' '
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <span className="block xl:text-lg font-semibold">
            {stateDate.startDate
              ? stateDate.startDate.format('DD MMM')
              : 'Check-in'}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {stateDate.startDate ? 'Check-in' : 'Adicionar data'}
          </span>
        </div>
      </div>
    );
  };

  const renderInputCheckOutDate = () => {
    const focused = focusedInput === 'endDate';
    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} flex-shrink-0 items-center space-x-3 cursor-pointer ${
          focused ? 'nc-hero-field-focused' : ' '
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <span className="block xl:text-lg font-semibold">
            {stateDate.endDate
              ? stateDate.endDate.format('DD MMM')
              : 'Check-out'}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {stateDate.endDate ? 'Check-out' : 'Adicionar data'}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`StayDatesRangeInput relative flex-shrink-0 flex z-10 [ lg:nc-flex-2 ] ${
        focusedInput ? 'nc-date-focusedInput' : 'nc-date-not-focusedInput'
      }`}
    >
      <div className="absolute inset-0 flex">
        <DateRangePicker
          startDate={stateDate.startDate}
          endDate={stateDate.endDate}
          focusedInput={focusedInput}
          onDatesChange={(date) => onChangeState(date)}
          onFocusChange={handleDateFocusChange}
          numberOfMonths={
            numberOfMonths || (windowSize.width <= 1024 ? 1 : undefined)
          }
          startDateId={'nc-hero-stay-startDateId'}
          endDateId={'nc-hero-stay-endDateId'}
          daySize={windowSize.width > 500 ? 56 : undefined}
          orientation={'horizontal'}
          showClearDates
          noBorder
          hideKeyboardShortcutsPanel
          anchorDirection={anchorDirection}
          customArrowIcon={<div />}
          reopenPickerOnClearDates
          isDayBlocked={(day) => isBlockedDay(day)}
          isOutsideRange={isOutsideRange}
        />
      </div>

      <div
        className={`flex flex-col lg:flex-row lg:items-center w-full flex-shrink-0 relative  ${wrapClassName}`}
      >
        {renderInputCheckInDate()}

        {renderInputCheckOutDate()}
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
