import React from 'react';
import NcImage from 'shared/NcImage/NcImage';
import { Link } from 'react-router-dom';
import convertNumbThousand from 'utils/convertNumbThousand';
import { Hotel } from 'hooks/hotels/types';
import { parseHotelToUrl } from 'utils/parseUrl';

export interface CardCategory3Props {
  size?: 'default' | 'small';
  className?: string;
  taxonomy: Hotel;
}

const CardCategory3: React.FC<CardCategory3Props> = ({
  size = 'default',
  className = '',
  taxonomy,
}) => {
  const { name, mainPhoto, totalRooms, city, title } = taxonomy;
  return (
    <Link
      to={`/pesquisa/${parseHotelToUrl(name)}`}
      className={`nc-CardCategory3 flex flex-col ${className}`}
      data-nc-id="CardCategory3"
    >
      <div
        className={
          'flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group'
        }
      >
        <NcImage
          src={mainPhoto.data.attributes.url}
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 truncate">
        <h2
          className={
            'text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate'
          }
        >
          {title}
        </h2>
        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          {size === 'default' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
          <span className="">{city}</span>
        </div>
        <span
          className={
            'block mt-2 text-sm text-neutral-6000 dark:text-neutral-400'
          }
        >
          {convertNumbThousand(totalRooms || 0)} quartos
        </span>
      </div>
    </Link>
  );
};

export default CardCategory3;
