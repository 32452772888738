import React from 'react';
import LocationInput from './LocationInput';
import GuestsInput from './GuestsInput';
import { FocusedInputShape } from 'react-dates';
import StayDatesRangeInput from './StayDatesRangeInput';
import ButtonSubmit from './ButtonSubmit';
import moment from 'moment';
import momentLocale from 'helpers/momentLocale';
import { useFilter } from 'hooks/filter';
import { parseFilterToUrl, parseUrlToFilter } from 'utils/parseUrlFilter';
import { DateRange, Guests } from 'hooks/filter/types';
import { useLocation } from 'react-router-dom';
import { useCalendar } from 'hooks/calendar';
import { enumerateDaysBetweenDates } from 'utils/daysBetween';

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = '';
const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(4, 'days'),
};
const defaultGuestValue: Guests = {
  adultMembersGuests: 0,
  adultNonMembersGuests: 0,
  kidMembersGuests: 0,
  kidNonMembersGuests: 0,
};

const StaySearchForm: React.FC<StaySearchFormProps> = ({
  haveDefaultValue = false,
}) => {
  momentLocale();
  const location = useLocation();
  const { blockDates } = useCalendar();
  const { filter, setFilter } = useFilter();
  const [dateRangeValue, setDateRangeValue] = React.useState<DateRange>({
    startDate: null,
    endDate: null,
  });
  const [locationInputValue, setLocationInputValue] = React.useState('');
  const [guestValue, setGuestValue] = React.useState<Guests>(defaultGuestValue);
  const [totalGuests, setTotalGuests] = React.useState(0);

  const [dateFocused, setDateFocused] =
    React.useState<FocusedInputShape | null>(null);

  //
  React.useEffect(() => {
    if (location) {
      if (location?.pathname) {
        if (location.pathname.startsWith('/pesquisa/')) {
          const searchHotel = location.pathname.split('/pesquisa/')[1];
          const searchFilters = location.search;
          const newFilter = parseUrlToFilter({
            hotel: searchHotel,
            searchParams: searchFilters.slice(1),
          });
          setDateRangeValue(
            {
              startDate: newFilter?.checkInDate,
              endDate: newFilter?.checkOutDate,
            } || {
              startDate: null,
              endDate: null,
            }
          );
          setLocationInputValue(newFilter?.hotel || '');
          setTotalGuests(newFilter?.totalGuests || 0);
          setGuestValue({
            ...defaultGuestValue,
            ...newFilter?.guests,
          });
        }
      }
    } else if (haveDefaultValue) {
      setDateRangeValue(defaultDateRange);
      setLocationInputValue(defaultLocationValue);
      setGuestValue(defaultGuestValue);
    }
  }, [location]);
  //

  const searchUrl = React.useMemo(() => {
    return parseFilterToUrl(filter);
  }, [filter]);

  const searchBlockDates = React.useMemo(() => {
    if (blockDates?.length && filter?.hotel) {
      const hotelBlockDates = blockDates?.filter((rangeDates) => {
        return filter?.hotel
          ? rangeDates?.hotels?.includes(filter?.hotel)
          : null;
      });
      const datesBetween = hotelBlockDates?.map((rangeDates) => {
        return enumerateDaysBetweenDates(
          moment(rangeDates?.startDate),
          moment(rangeDates?.endDate)
        );
      });
      return datesBetween.length
        ? datesBetween?.reduce((previousDates, currentDates) => [
            ...previousDates,
            ...currentDates,
          ])
        : [];
    }
    if (blockDates?.length) {
      const hotelBlockDates = blockDates?.filter((rangeDates) => {
        return rangeDates?.hotels?.length == 3;
      });
      const datesBetween = hotelBlockDates?.map((rangeDates) => {
        return enumerateDaysBetweenDates(
          moment(rangeDates?.startDate),
          moment(rangeDates?.endDate)
        );
      });
      return datesBetween.length
        ? datesBetween?.reduce((previousDates, currentDates) => [
            ...previousDates,
            ...currentDates,
          ])
        : [];
    }
    return [];
  }, [blockDates, filter]);

  const onChangeLocation = (hotel: string) => {
    setLocationInputValue(hotel);
    setFilter({
      ...filter,
      checkInDate: filter?.checkInDate || null,
      checkOutDate: filter?.checkOutDate || null,
      hotel,
    });
  };

  const onChangeDateRange = (rangeDate: DateRange) => {
    setDateRangeValue(rangeDate);
    setFilter({
      ...filter,
      checkInDate: rangeDate.startDate,
      checkOutDate: rangeDate.endDate,
      rangeDate,
    });
  };

  const onChangeGuestValue = (guests: Guests) => {
    setTotalGuests(
      (guests?.adultMembersGuests || 0) +
        (guests?.adultNonMembersGuests || 0) +
        (guests?.kidMembersGuests || 0) +
        (guests?.kidNonMembersGuests || 0) +
        (guests?.babyGuests || 0)
    );

    setGuestValue(guests);
  };

  React.useEffect(() => {
    if (totalGuests > 0)
      setFilter({
        ...filter,
        checkInDate: filter?.checkInDate || null,
        checkOutDate: filter?.checkOutDate || null,
        totalGuests,
        guests: guestValue,
      });
  }, [totalGuests, guestValue]);

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex flex-col md:flex-row  rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0">
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => onChangeLocation(e)}
          onInputDone={() => setDateFocused('startDate')}
        />
        <StayDatesRangeInput
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onChange={(data) => onChangeDateRange(data)}
          unavaibleDates={searchBlockDates}
        />
        <GuestsInput
          defaultValue={guestValue}
          onChange={(data) => onChangeGuestValue(data)}
          totalGuests={totalGuests}
        />
        {/* BUTTON SUBMIT OF FORM */}
        <div className="px-4 py-4 lg:py-0 flex items-center justify-center">
          <ButtonSubmit url={searchUrl} />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
