import range from 'lodash/range';

export const CPFValidator = (data: string) => {
  const cpf = data.replace(/\.|-/g, '');
  let sum = 0;
  let remainder: number;

  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;

  // Validate first digit
  range(0, 9).map((item) => {
    sum += parseInt(cpf.charAt(item), 10) * (10 - item);
    return sum;
  });

  remainder = 11 - (sum % 11);
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.charAt(9), 10)) return false;

  // Validate second digit
  sum = 0;
  range(0, 10).map((item) => {
    sum += parseInt(cpf.charAt(item), 10) * (11 - item);
    return sum;
  });

  remainder = 11 - (sum % 11);
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.charAt(10), 10)) return false;

  return true;
};
