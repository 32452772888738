// import { getDate } from 'utils/getDate';
import React from 'react';
import { Reservation } from './types';

type ReservationContextData = {
  reservation: Reservation;
  setReservation(data: Reservation): void;
  reservationId?: number;
  setReservationId(id: number): void;
};

export const ReservationContext = React.createContext(
  {} as ReservationContextData
);

export interface ReservationProviderProps {
  children: React.ReactNode;
}

const ReservationProvider: React.FC<ReservationProviderProps> = ({
  children,
}) => {
  const [reservation, setReservation] = React.useState<Reservation>(
    {} as Reservation
  );
  const [reservationId, setReservationId] = React.useState<number | undefined>(
    undefined
  );

  return (
    <ReservationContext.Provider
      value={{
        reservation,
        setReservation,
        reservationId,
        setReservationId,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export const useReservation = () => {
  const context = React.useContext(ReservationContext);

  return context;
};

export default ReservationProvider;
