// import { getDate } from 'utils/getDate';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { api } from 'services/api';
import { ReservationDate, DateResponse } from './types';

type CalendarContextData = {
  seasonalDates?: ReservationDate[];
  seasonalDatesLoading: boolean;
  blockDates?: ReservationDate[];
  blockDatesLoading: boolean;
};

export const CalendarContext = React.createContext({} as CalendarContextData);

export interface CalendarProviderProps {
  children: React.ReactNode;
}

export const fetchSeasonalDates = async () => {
  const nowDate = moment().format('YYYY-MM-DD');
  const seasonalDatesUrl = 'api/seasonal-dates';
  const filterParam = `?populate[hotels][fields]=name&filters[startDate][$gte]=${nowDate}`;
  const { data: seasonalDatesResponse } = await api.get<DateResponse>(
    `${seasonalDatesUrl}${filterParam}`
  );
  const seasonalDatesData = seasonalDatesResponse.data.map(
    (seasonalDateResponse) => {
      return {
        startDate: seasonalDateResponse.attributes.startDate,
        endDate: seasonalDateResponse.attributes.endDate,
        hotels: seasonalDateResponse.attributes.hotels.data.map((hotel) => {
          return hotel.attributes.name;
        }),
      };
    }
  );
  return seasonalDatesData;
};

export const fetchBlockDates = async () => {
  const nowDate = moment().format('YYYY-MM-DD');
  const blockDatesUrl = 'api/block-dates';
  const filterParam = `?populate[hotels][fields]=name&filters[endDate][$gte]=${nowDate}`;
  const { data: blockDatesResponse } = await api.get<DateResponse>(
    `${blockDatesUrl}${filterParam}`
  );
  const blockDatesData = blockDatesResponse.data.map((blockDateResponse) => {
    return {
      startDate: blockDateResponse.attributes.startDate,
      endDate: blockDateResponse.attributes.endDate,
      hotels: blockDateResponse.attributes.hotels.data.map((hotel) => {
        return hotel.attributes.name;
      }),
    };
  });
  return blockDatesData;
};

const CalendarProvider: React.FC<CalendarProviderProps> = ({ children }) => {
  const { data: seasonalDates, isLoading: seasonalDatesLoading } = useQuery(
    'getSeasonalDates',
    () => {
      return fetchSeasonalDates();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: blockDates, isLoading: blockDatesLoading } = useQuery(
    'getBlockDates',
    () => {
      return fetchBlockDates();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <CalendarContext.Provider
      value={{
        seasonalDates,
        seasonalDatesLoading,
        blockDates,
        blockDatesLoading,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => {
  const context = React.useContext(CalendarContext);

  return context;
};

export default CalendarProvider;
