import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import MyRouter from 'routers/index';
import { queryClient } from 'services/api';

import FilterProvider from 'hooks/filter';
import HotelsProvider from 'hooks/hotels';
import RoomsProvider from 'hooks/rooms';
import ReservationProvider from 'hooks/reservation';
import CalendarProvider from 'hooks/calendar';

import * as serviceWorkerRegistration from 'helpers/serviceWorker/serviceWorkerRegistration';

serviceWorkerRegistration.register();

const App: React.FC = () => {
  return (
    <div className="bg-white text-base text-neutral-900 dark:bg-neutral-900 dark:text-neutral-200">
      <img
        src="https://res.cloudinary.com/dan89lsmw/image/upload/v1670322896/selo_teste4_7ed5402c00.png"
        className="fixed top-0 right-0 z-50 w-52"
      />
      <QueryClientProvider client={queryClient}>
        <ReservationProvider>
          <CalendarProvider>
            <FilterProvider>
              <HotelsProvider>
                <RoomsProvider>
                  <MyRouter />
                  <ReactQueryDevtools initialIsOpen={false} />
                </RoomsProvider>
              </HotelsProvider>
            </FilterProvider>
          </CalendarProvider>
        </ReservationProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
