import { useFilter } from 'hooks/filter';
import { useRooms } from 'hooks/rooms';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'shared/Header/Header';
import { parseUrlToFilter } from 'utils/parseUrlFilter';

const SiteHeader: React.FC = () => {
  const location = useLocation();
  const { setFilter } = useFilter();
  const { setOpenRoomId, setRoomsFilter } = useRooms();
  React.useEffect(() => {
    if (location?.pathname) {
      if (location.pathname.startsWith('/quarto/')) {
        const newRoom = location.pathname.split('/')[3];
        setOpenRoomId(newRoom);
      } else if (location.pathname.startsWith('/pesquisa/')) {
        const searchHotel = location.pathname.split('/pesquisa/')[1];
        const searchFilters = location.search;
        const newFilter = parseUrlToFilter({
          hotel: searchHotel,
          searchParams: searchFilters.slice(1),
        });
        setRoomsFilter(newFilter);
        setFilter(newFilter);
      }
    }
  }, [location]);
  return <Header />;
};

export default SiteHeader;
