import React from 'react';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import SectionGridFilterCard from './SectionGridFilterCard';
import { Helmet } from 'react-helmet';
import { useRooms } from 'hooks/rooms';
import { useCalendar } from 'hooks/calendar';
import moment from 'moment';
import { useHotels } from 'hooks/hotels';

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: React.FC<ListingStayPageProps> = ({
  className = '',
}) => {
  const { allHotels } = useHotels();
  const { filteredRooms, roomsFilter } = useRooms();
  const { blockDates } = useCalendar();

  const hotel = React.useMemo(() => {
    return allHotels?.find((hotel) => {
      return hotel.name == roomsFilter?.hotel;
    });
  }, [roomsFilter, allHotels]);

  const allBlockedHotels = React.useMemo(() => {
    if (blockDates) {
      const hotels = blockDates?.map((blockedDate) => {
        const momentStart = moment(blockedDate?.startDate);
        const momentEnd = moment(blockedDate?.endDate);
        if (
          roomsFilter?.checkInDate?.isBetween(momentStart, momentEnd) ||
          roomsFilter?.checkOutDate?.isBetween(momentStart, momentEnd)
        ) {
          return blockedDate?.hotels;
        } else return [];
      });
      const blockedHotels = hotels.length
        ? hotels?.reduce((previousHotels, currentHotels) => {
            return [
              ...previousHotels,
              ...currentHotels?.filter((hotel) => {
                return !previousHotels?.includes(hotel);
              }),
            ];
          })
        : [];
      return blockedHotels;
    }
    return [];
  }, [blockDates, roomsFilter]);

  const showRooms = React.useMemo(() => {
    return filteredRooms?.filter((room) => {
      return room.avaible && !allBlockedHotels.includes(room?.hotel);
    });
  }, [filteredRooms, allBlockedHotels]);

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Hotéis Clube dos Oficiais Militares Mineiros</title>
      </Helmet>
      <BgGlassmorphism />
      <SectionHeroArchivePage
        className="pt-10 pb-24 lg:pb-24 lg:pt-16 "
        hotel={hotel}
      />

      <div className="container relative mt-16 space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <SectionGridFilterCard className="pb-24 lg:pb-32" rooms={showRooms} />
      </div>
    </div>
  );
};

export default ListingStayPage;
