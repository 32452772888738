import React from 'react';
import SectionHero from 'components/SectionHero/SectionHero';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionGridFeaturePlaces from './SectionGridFeaturePlaces';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
// import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import { Helmet } from 'react-helmet';
import { useRooms } from 'hooks/rooms';
import { useHotels } from 'hooks/hotels';

const PageHome: React.FC = () => {
  const { allRooms } = useRooms();
  const { allHotels } = useHotels();
  const carapebusRooms = allRooms?.filter((room) => {
    return room.hotel == 'Carapebus';
  });
  const boaEsperancaRooms = allRooms?.filter((room) => {
    return room.hotel == 'Boa Esperança';
  });
  const hotelDeTransitoRooms = allRooms?.filter((room) => {
    return room.hotel == 'Hotel de Trânsito';
  });

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Hotéis Clube dos Oficiais Militares Mineiros</title>
      </Helmet>
      {/* SECTION HERO */}
      <SectionHero className="pt-10 lg:pt-20 pb-16 relative h-[calc(100vh-100px)]" />

      <div className="container relative mt-16 space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces
            heading="Opções disponíveis"
            rooms={allRooms}
            hotel={'hoteis'}
          />
        </div>

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={allHotels}
          uniqueClassName="PageHome_s1"
          heading="Todos os Hotéis"
          subHeading="Veja os hotéis disponíveis"
          itemPerRow={3}
          categoryCardType="card5"
        />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces
            heading="Colônia de Férias Carapebus"
            rooms={carapebusRooms}
            hotel="Carapebus"
          />
        </div>

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces
            heading="Pousada Boa Esperança"
            rooms={boaEsperancaRooms}
            hotel="Boa Esperança"
          />
        </div>

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces
            heading="Hotel de Trânsito"
            rooms={hotelDeTransitoRooms}
            hotel="Hotel de Trânsito"
          />
        </div>
      </div>
    </div>
  );
};

export default PageHome;
