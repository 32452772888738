import { useReservation } from 'hooks/reservation';
import { RoomResponse } from 'hooks/rooms/types';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { apiComm, apiStrapi } from 'services/api';
import { enumerateDaysBetweenDates } from 'utils/daysBetween';
import { parseUrlFilterRoomInDate } from 'utils/parseUrlFilterRooms';
import {
  AssociateInDateResponse,
  GuestReservationResponse,
  PaymentForms,
  ReservationInput,
  ReservationResponse,
  RoomsAvailabilityResponse,
} from './types';

type PaymentsContextData = {
  MercadoPago: any;
  pixData: any;
  makePixPayment(data: PaymentForms): void;
  makePayment: (data: PaymentForms) => Promise<void>;
  makePreReservation: (data: PaymentForms) => Promise<void>;
  paymentError: boolean;
  setPaymentError: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: any;
  setErrorMessage: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
};

export const PaymentsContext = React.createContext({} as PaymentsContextData);

const PaymentsProvider: React.FC = ({ children }) => {
  const [pixData, setPixData] =
    React.useState<PaymentsContextData['pixData']>();
  const [MercadoPago, setMercadoPago] = React.useState<any>();
  const newWindow = window as any;

  React.useEffect(() => {
    if (newWindow.MercadoPago) {
      setMercadoPago(
        new newWindow.MercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY)
      );
    }
  }, [newWindow.MercadoPago]);

  const history = useHistory();
  const { reservation, setReservationId } = useReservation();
  const [loading, setLoading] = React.useState(false);
  const [paymentError, setPaymentError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<any>();

  const guestUrl = 'api/reservation-guests';
  const reservationUrl = 'api/reservations';
  const roomAvaibleUrl = 'api/room-availabilities';
  const roomUrl = 'api/rooms';
  const associateInDateUrl = 'api/associateInDate';

  const createReservationGuests = async (data: PaymentForms) => {
    const guestsId = data.guests.map(async (guest) => {
      const { data: guestPostResponse } =
        await apiStrapi.post<GuestReservationResponse>(guestUrl, {
          data: {
            name: guest.name,
            cpf: guest.document?.split('.').join('').split('-').join(''),
          },
        });
      return guestPostResponse.data.id;
    });
    return Promise.all(guestsId);
  };

  const createReservation = async (
    data: PaymentForms,
    reservationGuestsId: number[]
  ) => {
    if (
      reservation?.checkInDate &&
      reservation?.checkOutDate &&
      reservation?.totalGuests
    ) {
      const { data: reservationResponse } = await apiStrapi.post<
        ReservationResponse,
        any,
        ReservationInput
      >(reservationUrl, {
        data: {
          checkIn: reservation?.checkInDate,
          checkOut: reservation?.checkOutDate,
          associates: reservation?.associateGuests || 0,
          noAssociates: reservation?.noAssociateGuests || 0,
          childsAssociate: reservation?.childAssociateGuests || 0,
          childsNoAssociate: reservation?.childNoAssociateGuests || 0,
          babies: reservation?.babyGuests || 0,
          totalGuests: reservation?.totalGuests,
          email: data?.associateEmail,
          phone: data?.associatePhone
            ?.split('(')
            .join('')
            .split(')')
            .join('')
            .split('-')
            .join('')
            .split(' ')
            .join(''),
          associateCpf: data.associateDocument
            ?.split('.')
            .join('')
            .split('-')
            .join(''),
          associateName: data.associateName,
          observation: data.observation,
          price: parseFloat(reservation?.total?.toFixed(2) as string),
          priceWithInterest: data.total,
          paymentDateTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          paid: !data.pix,
          reservation_guests: reservationGuestsId,
          hotel: data.hotel,
          roomId: data.roomId,
          roomTitle: data.roomTitle,
          pix: data.pix,
          pixId: data.pixId,
        },
      });
      setReservationId(reservationResponse?.data?.id);
    }
  };

  const updateRoomAvailability = async () => {
    if (reservation && reservation.roomTotalRooms) {
      const maxGuests = reservation.roomTotalRooms;
      const lastDay = moment(reservation?.checkOutDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      const allDates = enumerateDaysBetweenDates(
        moment(reservation?.checkInDate),
        moment(lastDay)
      ).map((date) => {
        return date.format('YYYY-MM-DD');
      });
      const filterUrl = `?filters[room][id][$eq]=${reservation?.roomId}&filters[$and][0][date][$gte]=${reservation?.checkInDate}&filters[$and][1][date][$lte]=${lastDay}`;
      const { data: roomAvaibleResponse } =
        await apiStrapi.get<RoomsAvailabilityResponse>(
          `${roomAvaibleUrl}${filterUrl}`
        );
      const datesWithData = roomAvaibleResponse.data.map((date) => {
        return date.attributes.date;
      });

      const datesToPost = allDates.filter((date) => {
        return !datesWithData.includes(date);
      });

      datesToPost.map((date) => {
        apiStrapi.post(roomAvaibleUrl, {
          data: {
            date,
            availableRooms: maxGuests - 1,
            room: reservation?.roomId,
          },
        });
      });

      roomAvaibleResponse.data.map((dateResponse) => {
        const roomUrl = `/${dateResponse.id}`;
        apiStrapi.put(`${roomAvaibleUrl}${roomUrl}`, {
          data: {
            availableRooms: dateResponse.attributes.availableRooms - 1,
          },
        });
      });
    }
  };

  const createPixPayment = async (
    data: PaymentForms,
    payerDocument: string
  ) => {
    try {
      const fetchResponse = await fetch(
        process.env.REACT_APP_MERCADO_PAGO_API_URL || '',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transaction_amount: data?.total,
            payment_method_id: 'pix',
            payer: {
              email: data?.associateEmail,
              identification: {
                type: 'CPF',
                number: payerDocument,
              },
              type: 'customer',
              first_name: data?.associateName.split(' ')[0],
              last_name: data?.associateName.split(' ')[1],
            },
            notification_url:
              process.env.REACT_APP_MERCADO_PAGO_PIX_WEBHOOK_URL,
            additional_info: {
              payer: {
                phone: {
                  area_code: data?.associatePhone.split('(')[1].split(')')[0],
                  number: data?.associatePhone
                    .split(')')[1]
                    .split('-')
                    .join('')
                    .split(' ')
                    .join(''),
                },
              },
            },
          }),
        }
      );
      const response: any = JSON.parse(await fetchResponse.text());
      if (!fetchResponse.ok) {
        setErrorMessage(
          response.error?.message || 'Tente novamente mais tarde.'
        );
        throw new Error('error');
      }
      if (
        !response?.data?.body?.point_of_interaction?.transaction_data
          ?.qr_code ||
        !response?.data?.body?.point_of_interaction?.transaction_data
          ?.qr_code_base64
      ) {
        setErrorMessage(
          response.error?.message || 'Tente novamente mais tarde.'
        );
        throw new Error('error');
      }
      setPixData(response.data.body);
      const guestsId = await createReservationGuests(data);
      await createReservation(
        {
          ...data,
          pixId: response?.data.body.id,
        },
        guestsId
      );
      updateRoomAvailability();
      setLoading(false);
      history.push('/pagamento-pix');
    } catch (error: any) {
      setPaymentError(true);
      setLoading(false);
      throw new Error('error');
    }
  };

  const makePixPayment = async (data: PaymentForms) => {
    if (MercadoPago) {
      setLoading(true);
      const payerDocument = data?.associateDocument
        ?.split('.')
        .join('')
        .split('-')
        .join('');
      try {
        const populateRooms = parseUrlFilterRoomInDate(
          reservation?.checkInDate,
          reservation?.checkOutDate
        );
        const { data: roomResponse } = await apiStrapi.get<RoomResponse>(
          `${roomUrl}/${reservation?.roomId}?${populateRooms}`
        );
        if (
          roomResponse &&
          roomResponse?.data?.attributes?.room_availabilities?.data?.length == 0
        ) {
          try {
            const { data: associateInDateResponse } =
              await apiComm.get<AssociateInDateResponse>(
                `${associateInDateUrl}?cpf=${payerDocument}`
              );
            if (associateInDateResponse.inDate) {
              await createPixPayment(data, payerDocument);
            } else {
              setErrorMessage(
                'O CPF não corresponde a um sócio cadastrado. Confira o CPF e tente novamente.'
              );
              setPaymentError(true);
              setLoading(false);
            }
          } catch {
            setErrorMessage('Tente novamente.');
            setPaymentError(true);
            setLoading(false);
          }
        } else {
          setErrorMessage(
            'Parece que os quartos esgotaram. Por favor tente novamente.'
          );
          setPaymentError(true);
          setLoading(false);
        }
      } catch (error: any) {
        setErrorMessage(error?.[0]?.message);
        setPaymentError(true);
        setLoading(false);
      }
    } else {
      setErrorMessage('MercadoPago não carregado. Tente novamente.');
      setPaymentError(true);
      setLoading(false);
    }
  };

  const createPayment = async (
    data: PaymentForms,
    token: string,
    methodId: string,
    document: string
  ) => {
    try {
      const fetchResponse = await fetch(
        process.env.REACT_APP_MERCADO_PAGO_API_URL || '',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transaction_amount: data?.total,
            payment_method_id: methodId,
            installments: data?.installments,
            token: token,
            payer: {
              email: data?.associateEmail,
              identification: {
                type: 'CPF',
                number: document,
              },
              type: 'customer',
              first_name: data?.associateName.split(' ')[0],
              last_name: data?.associateName.split(' ')[1],
            },
            additional_info: {
              payer: {
                phone: {
                  area_code: data?.associatePhone.split('(')[1].split(')')[0],
                  number: data?.associatePhone
                    .split(')')[1]
                    .split('-')
                    .join('')
                    .split(' ')
                    .join(''),
                },
              },
            },
          }),
        }
      );
      const response: any = JSON.parse(await fetchResponse.text());
      if (!fetchResponse.ok) {
        setErrorMessage(
          response.error?.message || 'Tente novamente mais tarde.'
        );
        throw new Error('error');
      }
      setLoading(false);
      const guestsId = await createReservationGuests(data);
      await createReservation(data, guestsId);
      updateRoomAvailability();
      history.push('/pagamento-realizado');
    } catch (error: any) {
      setPaymentError(true);
      setLoading(false);
      throw new Error('error');
    }
  };

  const makePreReservation = async (data: PaymentForms) => {
    try {
      const populateRooms = parseUrlFilterRoomInDate(
        reservation?.checkInDate,
        reservation?.checkOutDate
      );
      const { data: roomResponse } = await apiStrapi.get<RoomResponse>(
        `${roomUrl}/${reservation?.roomId}?${populateRooms}`
      );
      if (
        roomResponse &&
        roomResponse?.data?.attributes?.room_availabilities?.data?.length == 0
      ) {
        setLoading(true);
        const document = data?.associateDocument
          ?.split('.')
          .join('')
          .split('-')
          .join('');
        const { data: associateInDateResponse } =
          await apiComm.get<AssociateInDateResponse>(
            `${associateInDateUrl}?cpf=${document}`
          );
        if (associateInDateResponse.inDate) {
          const guestsId = await createReservationGuests(data);
          await createReservation(data, guestsId);
          updateRoomAvailability();
          history.push('/reserva-realizada');
        } else {
          setErrorMessage(
            'O CPF não corresponde a um sócio cadastrado. Confira o CPF e tente novamente.'
          );
          setPaymentError(true);
          setLoading(false);
        }
      } else {
        setErrorMessage(
          'Parece que os quartos esgotaram. Por favor tente novamente.'
        );
        setPaymentError(true);
        setLoading(false);
      }
    } catch {
      setErrorMessage('Tente novamente.');
      setPaymentError(true);
      setLoading(false);
    }
  };

  const makePayment = async (data: PaymentForms) => {
    if (MercadoPago) {
      setLoading(true);
      const document = data?.associateDocument
        ?.split('.')
        .join('')
        .split('-')
        .join('');
      const cardOwnerDocument = data?.cardOwnerDocument
        ?.split('.')
        .join('')
        .split('-')
        .join('');
      const cardNumber = data?.cardNumber.split(' ').join('');
      try {
        const populateRooms = parseUrlFilterRoomInDate(
          reservation?.checkInDate,
          reservation?.checkOutDate
        );
        const { data: roomResponse } = await apiStrapi.get<RoomResponse>(
          `${roomUrl}/${reservation?.roomId}?${populateRooms}`
        );
        if (
          roomResponse &&
          roomResponse?.data?.attributes?.room_availabilities?.data?.length == 0
        ) {
          try {
            const { data: associateInDateResponse } =
              await apiComm.get<AssociateInDateResponse>(
                `${associateInDateUrl}?cpf=${document}`
              );
            if (associateInDateResponse.inDate) {
              const methodId = (
                await MercadoPago.getPaymentMethods({
                  bin: cardNumber.slice(0, 6),
                })
              )?.results?.[0]?.id;

              const token = await MercadoPago.createCardToken({
                cardNumber: cardNumber,
                cardholderName: data?.cardName,
                cardExpirationMonth: data?.cardExpirationDate?.split('/')[0],
                cardExpirationYear: data?.cardExpirationDate?.split('/')[1],
                securityCode: data?.cardCVC,
                identificationType: 'CPF',
                identificationNumber: cardOwnerDocument,
              });

              createPayment(data, token?.id, methodId, cardOwnerDocument);
            } else {
              setErrorMessage(
                'O CPF não corresponde a um sócio cadastrado. Confira o CPF e tente novamente.'
              );
              setPaymentError(true);
              setLoading(false);
            }
          } catch {
            setErrorMessage('Tente novamente.');
            setPaymentError(true);
            setLoading(false);
          }
        } else {
          setErrorMessage(
            'Parece que os quartos esgotaram. Por favor tente novamente.'
          );
          setPaymentError(true);
          setLoading(false);
        }
      } catch (error: any) {
        setErrorMessage(error?.[0]?.message);
        setPaymentError(true);
        setLoading(false);
      }
    } else {
      setErrorMessage('MercadoPago não carregado. Tente novamente.');
      setPaymentError(true);
      setLoading(false);
    }
  };

  return (
    <PaymentsContext.Provider
      value={{
        pixData,
        makePixPayment,
        MercadoPago,
        makePayment,
        makePreReservation,
        paymentError,
        setPaymentError,
        errorMessage,
        setErrorMessage,
        loading,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};

export const usePayment = () => {
  const context = React.useContext(PaymentsContext);

  return context;
};

export default PaymentsProvider;
