import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PaymentsProvider from 'hooks/payment';

import PageHome from 'containers/PageHome/PageHome';
import Page404 from 'containers/Page404/Page404';
import ListingStayPage from 'containers/ListingStayPage/ListingStayPage';
import ListingStayDetailPage from 'containers/ListingDetailPage/ListingStayDetailPage';
import CheckOutPage from 'containers/CheckOutPage/CheckOutPage';
import PayPage from 'containers/PayPage/PayPage';
import PixPayPage from 'containers/PixPayPage/PixPayPage';
import SiteHeader from 'containers/SiteHeader';

import Footer from 'shared/Footer/Footer';

import { Page } from './types';
import ScrollToTop from './ScrollToTop';

export const pages: Page[] = [
  { path: '/', exact: true, component: PageHome },
  { path: '/#', exact: true, component: PageHome },
  { path: '/pesquisa', component: ListingStayPage },
  { path: '/quarto', component: ListingStayDetailPage },
  { path: '/pagamento', component: CheckOutPage },
  { path: '/reserva', component: CheckOutPage },
  { path: '/pagamento-realizado', component: PayPage },
  { path: '/reserva-realizada', component: PayPage },
  { path: '/pagamento-pix', component: PixPayPage },
];

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <PaymentsProvider>
        <ScrollToTop />
        <SiteHeader />

        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}
          <Route component={Page404} />
        </Switch>
        <Footer />
      </PaymentsProvider>
    </BrowserRouter>
  );
};

export default Routes;
