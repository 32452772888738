import React from 'react';
import HeroSearchForm from 'components/HeroSearchForm/HeroSearchForm';
import GallerySlider from 'components/GallerySlider/HomePageSlider';
import { Media } from 'hooks/rooms/types';

export interface SectionHeroProps {
  className?: string;
}

const images: { data: Media[] } = {
  data: [
    {
      attributes: {
        url: 'https://res.cloudinary.com/dan89lsmw/image/upload/v1670199107/IMG_3904_84b048cd51.jpg',
      },
      id: 2,
    },
    {
      attributes: {
        url: 'https://res.cloudinary.com/dan89lsmw/image/upload/v1669905322/fachada_hotel_transito_2_1f426638ef.jpg',
      },
      id: 3,
    },
  ],
};

const SectionHero: React.FC<SectionHeroProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="container flex h-full flex-col lg:flex-row lg:items-center">
        <div className="z-30 flex flex-shrink-0 flex-col items-start space-y-8 pb-14 sm:space-y-10 lg:mr-10 lg:w-1/2 lg:pb-64 xl:mr-0 xl:pr-14">
          <h2 className="textShadow text-4xl font-semibold !leading-[114%] text-white md:text-5xl xl:text-7xl ">
            Hotel e Pousadas COMM
          </h2>
          <span className="textShadow text-base text-white dark:text-neutral-400 md:text-xl xl:text-3xl">
            Pesquise e reserve quartos em qualquer uma de nossas pousadas ou
            hotel totalmente online.
          </span>
        </div>
        <div className="absolute inset-x-0 top-[10px] z-10 w-full flex-grow">
          <div className="absolute z-20 h-[calc(100vh-100px)] w-full bg-black opacity-30" />
          <GallerySlider
            uniqueID="HomePageSlider"
            ratioClass=" "
            containerImageClass="w-[100vw] h-[calc(100vh-100px)]"
            galleryClass="w-full h-[calc(100vh-100px)]"
            imageClass="object-cover object-center w-full h-[calc(100vh-100px)]"
            mainImg={{
              data: {
                attributes: {
                  url: 'https://res.cloudinary.com/dan89lsmw/image/upload/v1670198974/IMG_3754_d7285cea4c.jpg',
                },
                id: 1,
              },
            }}
            galleryImgs={images}
            className="h-[calc(100vh-100px)] w-full"
          />
        </div>
      </div>

      <div className="container z-30 mb-12 w-full lg:mb-0 lg:-mt-60">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
