import React from 'react';
import range from 'lodash/range';
import { useForm } from 'react-hook-form';
import MaskedInput from 'shared/Input/MaskedInput';
import Label from 'components/Label/Label';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';
import { useRooms } from 'hooks/rooms';
import { useReservation } from 'hooks/reservation';
import { formatMoney } from 'helpers/formatMoney';
import moment from 'moment';
import momentLocale from 'helpers/momentLocale';
import { CPFValidator } from 'helpers/cpfValidator';
import { usePayment } from 'hooks/payment';
import { PaymentForms } from 'hooks/payment/types';
import Select from 'shared/Select/Select';
import Alert from 'shared/Alert/Alert';
import NcModal from 'shared/NcModal/NcModal';
import TextAreaInput from 'shared/Input/TextAreaInput';

export interface CheckOutPageProps {
  className?: string;
}

const maxInstallments = 3;

const installmentTaxes = {
  '1': 0.0499,
  '2': 0.0499 + 0.0239,
  '3': 0.0499 + 0.0478,
  '4': 0.0499 + 0.0717,
  '5': 0.0499 + 0.0899,
  '6': 0.0499 + 0.1049,
  '10': 0.0499 + 0.1459,
  '12': 0.0499 + 0.1741,
};

const CheckOutPage: React.FC<CheckOutPageProps> = ({ className = '' }) => {
  momentLocale();
  const {
    makePayment,
    makePixPayment,
    makePreReservation,
    loading,
    paymentError,
    setPaymentError,
    errorMessage,
    setErrorMessage,
  } = usePayment();
  const { openRoom } = useRooms();
  const { reservation, setReservation } = useReservation();
  const [payWithPix, setPayWithPix] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PaymentForms>({
    mode: 'onBlur',
    shouldUnregister: true,
    defaultValues: {
      associateDocument: '',
      associateName: '',
      associateEmail: '',
      associatePhone: '',
      cardNumber: '',
      cardName: '',
      cardOwnerDocument: '',
      cardExpirationDate: '',
      cardCVC: '',
      installments: 1,
      guests: [],
      observation: '',
    },
  });

  const reservationDate = React.useMemo(() => {
    const start = moment(reservation.checkInDate).format('DD');
    const end = moment(reservation.checkOutDate).format('DD MMM, YYYY');
    return `${start} - ${end}`;
  }, [reservation]);

  const isPreReservation = !!openRoom?.preReservation;
  const showCreditCardForm = !isPreReservation && !payWithPix;
  const submitButtonText = isPreReservation ? 'Reservar' : 'Confirmar e pagar';

  const associateDocument = register('associateDocument', {
    required: true,
    validate: (value) => CPFValidator(value),
  });
  const associateName = register('associateName', { required: true });
  const associateEmail = register('associateEmail', {
    required: true,
  });
  const associatePhone = register('associatePhone', {
    required: true,
  });
  const cardOwnerDocument = register('cardOwnerDocument', {
    required: showCreditCardForm,
    validate: (value) => (showCreditCardForm ? CPFValidator(value) : true),
  });
  const cardNumber = register('cardNumber', {
    required: showCreditCardForm,
  });
  const cardName = register('cardName', {
    required: showCreditCardForm,
  });
  const cardExpirationDate = register('cardExpirationDate', {
    required: showCreditCardForm,
  });
  const cardCVC = register('cardCVC', {
    required: showCreditCardForm,
  });
  const installments = register('installments', {
    required: showCreditCardForm,
  });
  const observation = register('observation', { required: false });

  const onSubmit = (data: PaymentForms) => {
    if (!openRoom) {
      setPaymentError(true);
      return setErrorMessage('Tente novamente, por favor.');
    }

    const total = reservation?.total || 0;

    const taxes = payWithPix
      ? 0.0099
      : installmentTaxes[
          `${data.installments}` as keyof typeof installmentTaxes
        ];

    const totalWithInterest = parseFloat((total / (1 - taxes)).toFixed(2));

    setReservation({
      ...reservation,
      guests: data?.guests,
      associateDocument: data?.associateDocument,
      associateEmail: data?.associateEmail,
      associateName: data?.associateName,
      associatePhone: data?.associatePhone,
      totalWithInterest,
    });
    if (isPreReservation)
      return makePreReservation({
        ...data,
        total: totalWithInterest,
        installments: Number(data.installments),
        preReservation: true,
        pix: false,
        hotel: openRoom.hotel,
        roomId: String(openRoom.id),
        roomTitle: openRoom.title,
      });
    if (payWithPix)
      return makePixPayment({
        ...data,
        total: totalWithInterest,
        installments: 1,
        preReservation: false,
        pix: true,
        hotel: openRoom.hotel,
        roomId: String(openRoom.id),
        roomTitle: openRoom.title,
      });
    return makePayment({
      ...data,
      total: totalWithInterest,
      installments: Number(data.installments),
      preReservation: false,
      pix: false,
      hotel: openRoom.hotel,
      roomId: String(openRoom.id),
      roomTitle: openRoom.title,
    });
  };

  const renderSidebar = () => {
    return (
      <div className="flex w-full flex-col space-y-6 border-neutral-200 px-0 dark:border-neutral-700 sm:space-y-8 sm:rounded-2xl sm:border sm:p-6 lg:max-w-md xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="w-full flex-shrink-0 sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 overflow-hidden rounded-2xl sm:aspect-h-4">
              <NcImage src={openRoom?.mainPhoto?.data?.attributes?.url} />
            </div>
          </div>
          <div className="space-y-3 py-5 sm:px-5">
            <div>
              <span className="text-sm text-neutral-500 line-clamp-1 dark:text-neutral-400">
                {openRoom?.city}
              </span>
              <span className="mt-1 block text-base font-medium">
                {openRoom?.title}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {openRoom?.totalRooms}{' '}
              <span>
                {(openRoom?.totalRooms || 0) > 1 ? 'quartos' : 'quarto'}
              </span>
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <h3 className="text-2xl font-semibold">Resumo</h3>
          {reservation.associateGuests ? (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>
                {reservation.associateGuests}{' '}
                {reservation.associateGuests > 1
                  ? 'Adultos Sócios'
                  : 'Adulto Sócio'}
              </span>
              <span>R$ {reservation.adultMembersGuestsTotalPrice}</span>
            </div>
          ) : null}
          {reservation.noAssociateGuests ? (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>
                {reservation.noAssociateGuests}{' '}
                {reservation.noAssociateGuests > 1
                  ? 'Adultos Não-sócios'
                  : 'Adulto Não-sócio'}
              </span>
              <span>R$ {reservation.adultNonMembersGuestsTotalPrice}</span>
            </div>
          ) : null}
          {reservation.childAssociateGuests ? (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>
                {reservation.childAssociateGuests}{' '}
                {reservation.childAssociateGuests > 1
                  ? 'Crianças Sócias'
                  : 'Criança Sócia'}
              </span>
              <span>R$ {reservation.kidMembersGuestsTotalPrice}</span>
            </div>
          ) : null}
          {reservation.childNoAssociateGuests ? (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>
                {reservation.childNoAssociateGuests}{' '}
                {reservation.childNoAssociateGuests > 1
                  ? 'Crianças Não-sócias'
                  : 'Criança Não-sócia'}
              </span>
              <span>R$ {reservation.kidNonMembersGuestsTotalPrice}</span>
            </div>
          ) : null}
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{formatMoney(reservation?.total || 0)}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="flex w-full flex-col space-y-8 border-neutral-200 px-0 dark:border-neutral-700 sm:rounded-2xl sm:border sm:p-6 xl:p-8">
        <h2 className="text-3xl font-semibold lg:text-4xl">
          Confirmação e {isPreReservation ? 'reserva' : 'pagamento'}
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Sua hospedagem</h3>
          </div>
          <div className="mt-6 flex flex-col divide-y divide-neutral-200 rounded-3xl border border-neutral-200 dark:divide-neutral-700 dark:border-neutral-700 sm:flex-row sm:divide-x sm:divide-y-0">
            <div className="flex flex-1 justify-between space-x-5 p-5">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Data</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {reservationDate}
                </span>
              </div>
            </div>
            <div className="flex flex-1 justify-between space-x-5 p-5">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Hóspedes</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {reservation?.totalGuests}{' '}
                  {(reservation?.totalGuests || 0) > 1 ? 'hóspedes' : 'hóspede'}
                </span>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="text-2xl font-semibold">Hóspedes</h3>
          <div className="mt-6">
            <div className="my-5 w-14 border-b border-neutral-200"></div>
            {range(1, (reservation?.totalGuests || 0) + 1).map((item) => {
              const name = register(`guests.${item}.name`, { required: true });
              const document = register(`guests.${item}.document`, {
                required: true,
                validate: (value) => CPFValidator(value),
              });
              return (
                <div key={`guest-${item}`}>
                  <div className="space-y-1">
                    <Label>Nome Hóspede {item} *</Label>
                    <MaskedInput
                      onChange={name.onChange}
                      onBlur={name.onBlur}
                      name={name.name}
                      inputRef={name.ref}
                      error={!!errors?.guests?.[item]?.name}
                    />
                    {!!errors?.guests?.[item]?.name ? (
                      <p className="text-xs text-error-100">
                        Campo Obrigatório
                      </p>
                    ) : (
                      <p className="text-xs">‎</p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <Label>CPF Hóspede {item} *</Label>
                    <MaskedInput
                      placeholder="123.456.789-00"
                      mask="000.000.000-00"
                      onChange={document.onChange}
                      onBlur={document.onBlur}
                      name={document.name}
                      inputRef={document.ref}
                      error={!!errors?.guests?.[item]?.document}
                    />
                    {errors?.guests?.[item]?.document?.type === 'validate' ? (
                      <p className="text-xs text-error-100">CPF Inválido</p>
                    ) : !!errors?.guests?.[item]?.document ? (
                      <p className="text-xs text-error-100">
                        Campo Obrigatório
                      </p>
                    ) : (
                      <p className="text-xs">‎</p>
                    )}
                  </div>
                  <div className="my-5 w-14 border-b border-neutral-200"></div>
                </div>
              );
            })}
          </div>
          <h3 className="text-2xl font-semibold">Observações</h3>
          <div className="mt-6">
            <div className="my-5 w-14 border-b border-neutral-200"></div>
            <div key={'observation'}>
              <div className="space-y-1">
                <Label>Observações para hospedagem</Label>
                <TextAreaInput
                  type="textarea"
                  placeholder="Escreva aqui suas observações para esta hospedagem"
                  onChange={observation.onChange}
                  onBlur={observation.onBlur}
                  name={observation.name}
                  ref={observation.ref}
                  sizeClass="h30- px-4 py-3"
                />
              </div>
              <div className="my-5 w-14 border-b border-neutral-200"></div>
            </div>
          </div>
          <h3 className="text-2xl font-semibold">
            {isPreReservation ? 'Dados do Associado' : 'Pagamento'}
          </h3>

          {!isPreReservation && (
            <div className="mt-3">
              <label
                htmlFor="default-toggle"
                className="relative inline-flex cursor-pointer items-center"
              >
                <Label>Pagar com Cartão</Label>
                <div className="relative mx-2">
                  <input
                    type="checkbox"
                    value=""
                    id="default-toggle"
                    className="peer sr-only"
                    onChange={() => setPayWithPix(!payWithPix)}
                    checked={payWithPix}
                  />
                  <div className="peer h-6 w-11  rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary-6000 peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                </div>
                <Label>Pagar com PIX</Label>
              </label>
            </div>
          )}
          <div className="mt-6">
            <div className="my-5 w-14 border-b border-neutral-200"></div>
            <div className="space-y-1">
              <Label>Nome Associado *</Label>
              <MaskedInput
                error={!!errors?.associateName}
                onChange={associateName.onChange}
                onBlur={associateName.onBlur}
                name={associateName.name}
                inputRef={associateName.ref}
              />
              {!!errors?.associateName ? (
                <p className="text-xs text-error-100">Campo Obrigatório</p>
              ) : (
                <p className="text-xs">‎</p>
              )}
            </div>
            <div className="space-y-1">
              <Label>CPF Associado *</Label>
              <MaskedInput
                placeholder="123.456.789-00"
                mask="000.000.000-00"
                error={!!errors?.associateDocument}
                onChange={associateDocument.onChange}
                onBlur={associateDocument.onBlur}
                name={associateDocument.name}
                inputRef={associateDocument.ref}
              />
              {errors?.associateDocument?.type === 'validate' ? (
                <p className="text-xs text-error-100">CPF Inválido</p>
              ) : !!errors?.associateDocument ? (
                <p className="text-xs text-error-100">Campo Obrigatório</p>
              ) : (
                <p className="text-xs">‎</p>
              )}
            </div>
            <div className="space-y-1">
              <Label>E-mail Associado *</Label>
              <MaskedInput
                placeholder="email@exemplo.com"
                error={!!errors?.associateEmail}
                onChange={associateEmail.onChange}
                onBlur={associateEmail.onBlur}
                name={associateEmail.name}
                inputRef={associateEmail.ref}
              />
              {!!errors?.associateEmail ? (
                <p className="text-xs text-error-100">Campo Obrigatório</p>
              ) : (
                <p className="text-xs">‎</p>
              )}
            </div>
            <div className="space-y-1">
              <Label>Celular Associado *</Label>
              <MaskedInput
                placeholder="(31) 12345-6789"
                mask="(00) 00000-0000"
                error={!!errors?.associatePhone}
                onChange={associatePhone.onChange}
                onBlur={associatePhone.onBlur}
                name={associatePhone.name}
                inputRef={associatePhone.ref}
              />
              {!!errors?.associatePhone ? (
                <p className="text-xs text-error-100">Campo Obrigatório</p>
              ) : (
                <p className="text-xs">‎</p>
              )}
            </div>
            {showCreditCardForm && (
              <>
                <div className="space-y-1">
                  <Label>Número do Cartão *</Label>
                  <MaskedInput
                    placeholder="1234 5678 9123 4567"
                    mask="0000 0000 0000 0000"
                    error={!!errors?.cardNumber}
                    onChange={cardNumber.onChange}
                    onBlur={cardNumber.onBlur}
                    name={cardNumber.name}
                    inputRef={cardNumber.ref}
                  />
                  {!!errors?.cardNumber ? (
                    <p className="text-xs text-error-100">Campo Obrigatório</p>
                  ) : (
                    <p className="text-xs">‎</p>
                  )}
                </div>
                <div className="space-y-1">
                  <Label>Nome no Cartão *</Label>
                  <MaskedInput
                    error={!!errors?.cardName}
                    onChange={cardName.onChange}
                    onBlur={cardName.onBlur}
                    name={cardName.name}
                    inputRef={cardName.ref}
                  />
                  {!!errors?.cardName ? (
                    <p className="text-xs text-error-100">Campo Obrigatório</p>
                  ) : (
                    <p className="text-xs">‎</p>
                  )}
                </div>
                <div className="space-y-1">
                  <Label>CPF do Titular do Cartão *</Label>
                  <MaskedInput
                    placeholder="123.456.789-00"
                    mask="000.000.000-00"
                    error={!!errors?.cardOwnerDocument}
                    onChange={cardOwnerDocument.onChange}
                    onBlur={cardOwnerDocument.onBlur}
                    name={cardOwnerDocument.name}
                    inputRef={cardOwnerDocument.ref}
                  />
                  {errors?.cardOwnerDocument?.type === 'validate' ? (
                    <p className="text-xs text-error-100">CPF Inválido</p>
                  ) : !!errors?.cardOwnerDocument ? (
                    <p className="text-xs text-error-100">Campo Obrigatório</p>
                  ) : (
                    <p className="text-xs">‎</p>
                  )}
                </div>
                <div className="flex space-x-5  ">
                  <div className="flex-1 space-y-1">
                    <Label>Data de Expiração *</Label>
                    <MaskedInput
                      placeholder="mm/yy"
                      mask="00/00"
                      error={!!errors?.cardExpirationDate}
                      onChange={cardExpirationDate.onChange}
                      onBlur={cardExpirationDate.onBlur}
                      name={cardExpirationDate.name}
                      inputRef={cardExpirationDate.ref}
                    />
                    {!!errors?.cardExpirationDate ? (
                      <p className="text-xs text-error-100">
                        Campo Obrigatório
                      </p>
                    ) : (
                      <p className="text-xs">‎</p>
                    )}
                  </div>
                  <div className="flex-1 space-y-1">
                    <Label>CVC *</Label>
                    <MaskedInput
                      placeholder="123"
                      mask="000"
                      error={!!errors?.cardCVC}
                      onChange={cardCVC.onChange}
                      onBlur={cardCVC.onBlur}
                      name={cardCVC.name}
                      inputRef={cardCVC.ref}
                    />
                    {!!errors?.cardCVC ? (
                      <p className="text-xs text-error-100">
                        Campo Obrigatório
                      </p>
                    ) : (
                      <p className="text-xs">‎</p>
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <Label>Parcelas *</Label>
                  <Select
                    error={!!errors?.installments}
                    onChange={installments.onChange}
                    onBlur={installments.onBlur}
                    name={installments.name}
                    ref={installments.ref}
                  >
                    {range(1, maxInstallments + 1).map((item) => {
                      const installmentValue =
                        (reservation?.total || 0) /
                        (1 -
                          installmentTaxes[
                            `${item}` as keyof typeof installmentTaxes
                          ]);
                      return (
                        <option key={`option-${item}`} value={item}>
                          {item} x{' '}
                          {formatMoney((installmentValue || 0) / item, 2)}
                        </option>
                      );
                    })}
                  </Select>
                  {!!errors?.installments ? (
                    <p className="text-xs text-error-100">Campo Obrigatório</p>
                  ) : (
                    <p className="text-xs">‎</p>
                  )}
                </div>
              </>
            )}
            <span>
              * Quaisquer dúvidas favor entrar em contato pelo tel{' '}
              <strong>3290-2604</strong>
            </span>
            <div className="pt-4">
              <ButtonPrimary loading={loading} type="submit">
                {submitButtonText}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </div>
    );
  };
  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 flex flex-col-reverse lg:mb-32 lg:flex-row">
        <div className="w-full lg:w-3/5 lg:pr-10 xl:w-2/3 ">{renderMain()}</div>
        <div className="hidden flex-grow lg:block">{renderSidebar()}</div>
        {paymentError ? (
          <NcModal
            isOpenProp={paymentError}
            noBackground
            renderContent={() => {
              return (
                <Alert type="error" onClose={() => setPaymentError(false)}>
                  <h4 className="pb-4 text-left font-bold">Erro</h4>
                  <p className="text-left">{errorMessage}</p>
                </Alert>
              );
            }}
            onCloseModal={() => setPaymentError(false)}
          />
        ) : null}
      </main>
    </div>
  );
};

export default CheckOutPage;
